import { IconButton } from "@mui/material";
import { BiMenu } from "react-icons/bi";
import { useSortable } from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';

const PriorityItem = ({group, t}) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: group.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    
    // console.log(group);
    

    return ( 
        <div key={group?.id} style={style} ref={setNodeRef} >
            <article className=" mb-4 flex items-center justify-between p-2 rounded-md bg-[#F3F6F9] border border-[#CECECE]">
                <section className="group-content-wrapper-left flex items-center gap-x-2">
                    <div className="w-fit" {...attributes} {...listeners}><IconButton><BiMenu /></IconButton></div>
                    {/* <div className="rounded-md overflow-hidden w-20 h-20">
                        <img src={defImg} alt="option-group-img" className="object-cover w-full h-full rounded-md" />
                    </div> */}
                    <div className="capitalize">
                        <p>{localStorage.getItem('i18nextLng') === 'ar' ? group?.optionGroupNameAr : group?.optionGroupName}</p>
                    </div>
                </section>

                {/* <section className="group-content-wrapper-right">
                    <div>
                        <IconButton><AiOutlineClose className="text-[#333] text-sm" /></IconButton>
                    </div>
                </section> */}

            </article>
        </div>
    );
}
 
export default PriorityItem;