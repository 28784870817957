import { useJsApiLoader } from '@react-google-maps/api';
const libraries = ["places"];

export const useGoogleMaps = () => {

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey : localStorage?.getItem('gkey'),   
        libraries
    });

    return { isLoaded, loadError };
};