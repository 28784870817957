import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getBranches = createAsyncThunk("getBranchesFuncs", async({page,size,col,dir,searchValue}, {rejectWithValue,dispatch})=>{
    // console.log(dir);
    let colCheck;
    
    if (col) {
        colCheck = 
            (col === 'Created At' || col === 'تم إنشاءه في') ? 'created_at' 
          : (col === 'Updated at' || col === 'تم التعديل في') ? 'updated_at' 
          : (col === 'Branch Name' || col === 'اسم الفرع' ) ? 'name' 
          : (col === 'Branch Arabic Name' || col === 'اسم الفرع العربى' ) ? 'name_ar' 
          : (col === 'العنوان' ) ? 'address' 
          : col.replace(/\s+/g, '').toLowerCase();
        // console.log(colCheck);
    }

    const url = !col ? `/my-restaurant/branches?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/my-restaurant/branches?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    
    // console.log(url);
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getSingleBranche = createAsyncThunk("getSingleBrancheFuncs", async(id, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/my-restaurant/branches/${id}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editBranche = createAsyncThunk("editBrancheFuncs", async({id,value}, {rejectWithValue,dispatch})=>{
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/branches/${id}`,value),{
            pending: 'loading...',
            success: 'Branch Edited Successfully 👌',
            },{toastId : "EditBranchToast"
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addtBranche = createAsyncThunk("addBrancheFuncs", async(value, {rejectWithValue,dispatch})=>{
    // console.log(value);
    try {
        const res = await toast.promise(axios.post(`/my-restaurant/branches`,value),{
            pending: 'loading...',
            success: 'Branch Added Successfully 👌',
            },{toastId : "AddBranchToast"
        })
        // console.log(res);
        return res.data

    } catch(error){
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    branches : [],
    singleBranch : [],
    error : null
}

export const BranchesSlice = createSlice({ 
    name: "BranchesSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getBranches.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getBranches.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.branches = action.payload
            // console.log(action.payload);
        })

        .addCase(getBranches.rejected, (state,action)=>{
            state.loading = false;
            state.branches = ""
            state.error = action.payload
        })

        .addCase(getSingleBranche.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getSingleBranche.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.singleBranch = action.payload
            // console.log(action.payload);
        })

        .addCase(getSingleBranche.rejected, (state,action)=>{
            state.loading = false;
            state.singleBranch = []
            state.error = action.payload
        })

        .addCase(editBranche.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(editBranche.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.singleBranch = action.payload
            // console.log(action.payload);
        })

        .addCase(editBranche.rejected, (state,action)=>{
            state.loading = false;
            state.singleBranch = []
            state.error = action.payload
        })

        .addCase(addtBranche.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addtBranche.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.branches = action.payload
            // console.log(action.payload);
        })

        .addCase(addtBranche.rejected, (state,action)=>{
            state.loading = false;
            state.branches = []
            state.error = action.payload
        })

    }
});

// export const {} = BranchesSlice.actions

export default BranchesSlice.reducer

