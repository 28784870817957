import {Routes,Route, useLocation, useNavigate } from 'react-router-dom';
import Login from '../../Pages/login';
import { useEffect, useState } from 'react';
import ForgetPassword from '../../Pages/forgetPassword';
import { useSelector } from 'react-redux';
import MainRoutes from './mainRoutes';
import VerificationCode from '../ForgetPassword-Steps/verificationCode';
import NewPassword from '../ForgetPassword-Steps/newPassword';
import Terms from '../../Pages/terms'
import AboutUs from '../../Pages/aboutus'
import PartnerStepForm from '../../Pages/stepForm';
import FirstChangePassword from '../../Pages/firstChangePassword';
import Privacy from '../../Pages/privacy';

const Routing = () => {
    const {token} = useSelector(state=>state?.auth)
    const navigate = useNavigate()
    // console.log(token);
    const path = useLocation()
    const [navCollapse, setnavCollapse] = useState(false)

    const toggleNavHanlder = ()=>{
        let toggle = navCollapse
        setnavCollapse(!toggle)
    }
    
    useEffect(() => {
        if(((localStorage.getItem('tk') === 'undefined' || !localStorage.getItem('tk'))  && (path.pathname.indexOf('dashboard') === 1))){
            navigate('/login',{ replace: true })
        }
        // console.log(path.pathname.indexOf('dashboard') === -1);
    }, [])

    return (
        <>
        {/* since login page contain no header nor side nav, the pages has been splitted into to sections first to check if the pathname contain login then we render the login page only other than this render the main layout with the page routes inside */}
            {path.pathname.indexOf('dashboard') === -1 ?
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/firstnewpassword' element={<FirstChangePassword />} />
                    <Route path='/complete-partner-registration' element={<PartnerStepForm />} />
                    <Route path='/forgetpassword' element={<ForgetPassword />} />
                    <Route path='/verificationcode' element={<VerificationCode />} />
                    <Route path='/reset-password' element={<NewPassword />} />
                    <Route path='/termsconditions' element={<Terms />} />
                    <Route path='/privacypolicies' element={<Privacy />} />
                    <Route path='/aboutus' element={<AboutUs />} />
                </Routes>

                :
                <>
                    <MainRoutes />
                </>
            }
        </>
    );
}

export default Routing;