import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";
import { toast } from "react-toastify";

export const getPackages = createAsyncThunk("getPackagesFunc", async({page,size,searchValue}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await axios.get(`/subscription-packages?${page ? `page=${page}` : `page=${1}`}&${size ? `size=${size}` : `size=${12}`}&${(searchValue && searchValue !== '') ? `search=${searchValue}` : ''}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getSubPackages = createAsyncThunk("getSubPackagesFunc", async({page,size}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await axios.get(`/subscription-packages/subscribed?${page ? `page=${page}` : `page=${1}`}&${size ? `size=${size}` : `size=${12}`}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const subscribePackage = createAsyncThunk("subscribePackageFunc", async({id,data}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await toast.promise(axios.post(`/subscription-packages/${id}/subscribe`,data),{
            pending : "Loading...",
            success : "Subscribed to packaged successfully"
        },{toastId : "subPackagesID"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const unSubscribePackage = createAsyncThunk("unSubscribePackageFunc", async(data, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await toast.promise(axios.post(`/subscription-packages/unsubscribe`,data),{
            pending : "Loading...",
            success : "Unsubscribed to packaged successfully"
        },{toastId : "unsubPackagesID"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editSubscribePackage = createAsyncThunk("editSubscribePackageFunc", async({id,data}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await toast.promise(axios.put(`/subscription-packages/subscribed/${id}`,data),{
            pending : "Loading...",
            success : "Packaged Updated successfully"
        },{toastId : "EditsubPackagesID"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

const initialState = {
    loading: false,
    packages : [],
    subPackages : [],
    error : null,
}
export const OrdersSlice = createSlice({ 
    name: "OrdersSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
    
        .addCase(getPackages.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getPackages.fulfilled, (state,action)=>{
            state.loading = false;
            state.packages = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getPackages.rejected, (state,action)=>{
            state.loading = false;
            state.packages = []
            state.error = action.payload
        })

        .addCase(getSubPackages.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getSubPackages.fulfilled, (state,action)=>{
            state.loading = false;
            state.subPackages = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getSubPackages.rejected, (state,action)=>{
            state.loading = false;
            state.subPackages = []
            state.error = action.payload
        })

        .addCase(subscribePackage.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(subscribePackage.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(subscribePackage.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(unSubscribePackage.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(unSubscribePackage.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(unSubscribePackage.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(editSubscribePackage.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(editSubscribePackage.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(editSubscribePackage.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })
    }
});

export default OrdersSlice.reducer

