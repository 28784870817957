import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import motor from '../../../../Assets/imgs/motorStateIcon.svg'
// import reserveation from '../../../../Assets/imgs/reserveStateIcon.svg'
import curbside from '../../../../Assets/imgs/curbsideStateIcon.svg'
import pickup from '../../../../Assets/imgs/pickupStateIcon.svg'
import { useDispatch, useSelector } from "react-redux";
import { getStatistics } from "../../../../Components/Redux/Slice/Report/report";
import NoDataEmoji from "../../../../Components/Common/nodataEmoje";

const ReportWeeklyStats = ({t}) => {

    const dispatch = useDispatch()

    const {report} = useSelector(state=>state)

    const [totalDelivery, settotalDelivery] = useState('')
    const [totalCurpside, settotalCurpside] = useState('')
    const [reservationPickup, setreservationPickup] = useState('')

    // let totalDelivery = report?.statistics?.data?.totalDeliveryOrders,
        // totalCurpside = report?.statistics?.data?.totalCurbsidePickupOrders

    useEffect(() => {
        let dateRange = 'last_7_days'
        const reportCall = async ()=>{
            await dispatch(getStatistics({dateRange})).then( (e)=>{
                    if(e?.payload?.message === 'SUCCESS'){
                        settotalDelivery(e?.payload?.data?.totalDeliveryOrders)
                        settotalCurpside(e?.payload?.data?.totalCurbsidePickupOrders)
                        setreservationPickup(e?.payload?.data?.totalRestaurantPickupOrders)
                    }
                })
        }
        reportCall()
    }, [dispatch])
    
    // console.log(report);


    const series = [totalDelivery&&totalDelivery, totalCurpside&&totalCurpside, reservationPickup&&reservationPickup ];
    let totalSales

    const calc = async ()=>{
        return totalSales = await series&&series?.reduce((a, b) => a + b)
    }


    calc().then((totalSales) => {
        return totalSales
    });

    const chartOptions = {
        chart: {
            type: 'donut',
        },
        labels: [t('reports.weekly_stats.icons.delivery'), t('reports.weekly_stats.icons.curbside'), t('reports.weekly_stats.icons.reservationPickup')],
        // labels: ['Delivery', 'Reservation', 'Curbside', 'Reservation Pickup'],
        dataLabels:{
            show : false,
            // formatter: function (series) {
            //     return val + "%"
            // },
        },
        legend: {
          show: false,
        },
        // title: {
        //     text: `Total: ${series.reduce((a, b) => a + b)}`,
        //     align: 'center',
        //     style: {
        //       fontWeight: 'bold',
        //       fontSize: '18px',
        //       color: '#555',
        //     },
        //   },
        plotOptions: {
            pie: {
                donut: {
                    size: '60%',
                    labels : {
                        show : true,
                        style: {
                            fontSize: '18px',
                            color: '#555',
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            alignItems: 'center',
                        },
                        // total: { //custome name its a callback function for the lable
                        //     show: true,
                        //     label: 'Sales',
                        //     formatter: ()=> {
                        //         calc().then((totalSales) => {
                        //             return totalSales
                        //         });
                        //     },
                        // },                    
                    }
                },
                customScale: 1,
            },
        },
        // annotations: {
        //   labels: series.map((value, index) => {
        //     const label = labels[index];
        //     const percentage = ((value / series.reduce((a, b) => a + b)) * 100).toFixed(1);
        //     return {
        //       text: `${label}: ${percentage}%`,
        //       position: 'center',
        //       offsetY: 0,
        //       style: {
        //         fontSize: '18px',
        //         fontWeight: 'bold',
        //         color: colors[index],
        //       },
        //     };
        //   }),
        // },
    };
    
    return ( 
        <article className="weekly-stats-wrapper pb-16">
            <section className="weekly-stats-title-wrapper text-[#464E5F] font-semibold text-[15px] capitalize">
                <h2>{t('dashboard.weekly_stats.title')}</h2>
            </section>

            

            {(report?.statistics?.data?.totalDeliveryOrders === 0 && report?.statistics?.data?.totalRestaurantPickupOrders === 0 && report?.statistics?.data?.totalCurbsidePickupOrders ===0) ?
            
            <div className="mt-8">
                <NoDataEmoji /> 
            </div>
            :
            <section className="weekly-stats-chart mt-5">
                <ReactApexChart options={chartOptions} series={series} type="donut" />

                <div className="donut-chart-custom-legend grid justify-center grid-cols-2 gap-5 gap-x-3 mt-6">

                    <div className="legend-item-wrapper flex items-center gap-x-2">
                        <div>
                            <span className="block p-2 rounded-md w-[40px] h-[40px] bg-[#DFDBE9]"><img src={motor} alt="delivery-icon" className="w-full h-full" /></span>
                        </div>

                        <div className="capitalize">
                            <p className="text-sm text-[#464E5F] font-bold">{totalDelivery}</p>
                            <p className="text-[12px] text-[#B5B5C3]">{t('dashboard.weekly_stats.icons.delivery')}</p>
                        </div>
                    </div>

                    {/* <div className="legend-item-wrapper flex items-center">
                        <div className={`${localStorage.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`}>
                            <span className="block p-2 rounded-md w-[40px] h-[40px] bg-[#c6345b32]"><img src={reserveation} alt="reserveation-icon" className="w-full h-full" /></span>
                        </div>

                        <div className="capitalize">
                            <p className="text-sm text-[#464E5F] font-bold">136</p>
                            <p className="text-[12px] text-[#B5B5C3]">reservation</p>
                        </div>
                    </div> */}

                    <div className="legend-item-wrapper flex items-center gap-x-2">
                        <div>
                            <span className="block p-2 rounded-md w-[40px] h-[40px] bg-[#5cac7d34]"><img src={curbside} alt="curbside-icon" className="w-full h-full" /></span>
                        </div>

                        <div className="capitalize">
                            <p className="text-sm text-[#464E5F] font-bold">{totalCurpside}</p>
                            <p className="text-[12px] text-[#B5B5C3]">{t('dashboard.weekly_stats.icons.curbside')}</p>
                        </div>
                    </div>

                    <div className="legend-item-wrapper flex items-center col-span-2 gap-x-2">
                        <div>
                            <span className="block p-2 rounded-md w-[40px] h-[40px] bg-[#d9ad5637]"><img src={pickup} alt="pickup-icon" className="w-full h-full" /></span>
                        </div>

                        <div className="capitalize">
                            <p className="text-sm text-[#464E5F] font-bold">{reservationPickup}</p>
                            <p className="text-[12px] text-[#B5B5C3]">{t('reports.weekly_stats.icons.reservationPickup')}</p>
                        </div>
                    </div>
                </div>
            </section>
            }
        </article>
    );
}

export default ReportWeeklyStats;

// import ReactApexChart from "react-apexcharts";
// import ApexCharts from 'apexcharts';
// import { useState } from "react";
// import motor from '../../../Assets/imgs/motorStateIcon.svg'
// import reserveation from '../../../Assets/imgs/reserveStateIcon.svg'
// import curbside from '../../../Assets/imgs/curbsideStateIcon.svg'
// import pickup from '../../../Assets/imgs/pickupStateIcon.svg'

// const ReportWeeklyStats = () => {
//     const series = [100, 50, 50, 30, 15];

//     const chartOptions = {
//         chart: {
//           type: 'donut',
//         },
//         labels: ['Apple', 'Banana', 'Mango', 'Orange', 'Pineapple'],
//         dataLabels:{
//             show : false,
//             // formatter: function (series) {
//             //     return val + "%"
//             // },
//         },
//         legend: {
//           show: false,
//         },
//         // title: {
//         //     text: `Total: ${series.reduce((a, b) => a + b)}`,
//         //     align: 'center',
//         //     style: {
//         //       fontWeight: 'bold',
//         //       fontSize: '18px',
//         //       color: '#555',
//         //     },
//         //   },
//         plotOptions: {
//             pie: {
//                 donut: {
//                     size: '60%',
//                     labels : {
//                         show : true,
//                         style: {
//                             fontSize: '18px',
//                             color: '#555',
//                             display: 'flex',
//                             flexDirection: 'column-reverse',
//                             alignItems: 'center',
//                           },
//                         total: { //custome name its a callback function for the lable
//                             show: true,
//                             label: 'Sales',
//                             formatter: ()=> {
//                                 return series.reduce((a, b) => a + b);
//                             },
//                         },                    
//                     }
//                 },
//                 customScale: 1,
//             },
//         },
//         // annotations: {
//         //   labels: series.map((value, index) => {
//         //     const label = labels[index];
//         //     const percentage = ((value / series.reduce((a, b) => a + b)) * 100).toFixed(1);
//         //     return {
//         //       text: `${label}: ${percentage}%`,
//         //       position: 'center',
//         //       offsetY: 0,
//         //       style: {
//         //         fontSize: '18px',
//         //         fontWeight: 'bold',
//         //         color: colors[index],
//         //       },
//         //     };
//         //   }),
//         // },
//       };
    


//     return ( 
//         <article className="weekly-stats-wrapper">
//             <section className="weekly-stats-title-wrapper text-[#464E5F] font-semibold text-[15px] capitalize">
//                 <h2>Weekly Sales Stats</h2>
//             </section>

//             <section className="weekly-stats-chart mt-5">
//                 <ReactApexChart options={chartOptions} series={series} type="donut" />

//                 <div className="donut-chart-custom-legend grid justify-center grid-cols-2 gap-6 mt-6">

//                     <div className="legend-item-wrapper flex items-center">
//                         <div className="mr-2">
//                             <span className="block p-2 rounded-md w-[40px] h-[40px] bg-[#DFDBE9]"><img src={motor} alt="delivery-icon" className="w-full h-full" /></span>
//                         </div>

//                         <div className="capitalize">
//                             <p className="text-sm text-[#464E5F] font-bold">124</p>
//                             <p className="text-[12px] text-[#B5B5C3]">deivery</p>
//                         </div>
//                     </div>

//                     <div className="legend-item-wrapper flex items-center">
//                         <div className="mr-2">
//                             <span className="block p-2 rounded-md w-[40px] h-[40px] bg-[#c6345b32]"><img src={reserveation} alt="reserveation-icon" className="w-full h-full" /></span>
//                         </div>

//                         <div className="capitalize">
//                             <p className="text-sm text-[#464E5F] font-bold">136</p>
//                             <p className="text-[12px] text-[#B5B5C3]">reservation</p>
//                         </div>
//                     </div>

//                     <div className="legend-item-wrapper flex items-center">
//                         <div className="mr-2">
//                             <span className="block p-2 rounded-md w-[40px] h-[40px] bg-[#5cac7d34]"><img src={curbside} alt="curbside-icon" className="w-full h-full" /></span>
//                         </div>

//                         <div className="capitalize">
//                             <p className="text-sm text-[#464E5F] font-bold">188</p>
//                             <p className="text-[12px] text-[#B5B5C3]">curbside</p>
//                         </div>
//                     </div>

//                     <div className="legend-item-wrapper flex items-center">
//                         <div className="mr-2">
//                             <span className="block p-2 rounded-md w-[40px] h-[40px] bg-[#d9ad5637]"><img src={pickup} alt="pickup-icon" className="w-full h-full" /></span>
//                         </div>

//                         <div className="capitalize">
//                             <p className="text-sm text-[#464E5F] font-bold">124</p>
//                             <p className="text-[12px] text-[#B5B5C3]">deivery</p>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </article>
//     );
// }

// export default ReportWeeklyStats;