import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const login = createAsyncThunk("loginFunc", async(values, {rejectWithValue,dispatch})=>{
    try {
        const res = await toast.promise(axios.post('/auth/login',values),
        {
            pending: 'loading...',
            success: 'Login Success 👌',
            // error: 'Promise rejected 🤯'
        },{toastId : "loginToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response.data);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const sendEmail = createAsyncThunk("sendEmailFunc", async(values, {rejectWithValue,dispatch})=>{
    try {
        const res = await toast.promise(axios.post('/auth/forgot-password',values),
        {
            pending: 'loading...',
            success: 'Email Has Been Sent Successfully 👌',
            // error: 'Promise rejected 🤯'
        },{toastId : "SendEmailToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response.data);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const resetPassword = createAsyncThunk("resetPasswordFunc", async(values, {rejectWithValue,dispatch})=>{
    try {
        const res = await toast.promise(axios.put('/auth/reset-password',values),
        {
            pending: 'loading...',
            success: 'Password Has Been Reset Successfully 👌',
            // error: 'Promise rejected 🤯'
        },{toastId : "resetPasswordToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response.data);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})
// const token = localStorage.getItem('token') ? localStorage.getItem('token') : null

const initialState = {
    loading: false,
    // by creating token like this we make sure token is available even if the page refreshed
    token : localStorage.getItem('tk') ? localStorage.getItem('tk') : null,
    passwordResetVerification : null,
    role : localStorage.getItem('role') ? localStorage.getItem('role') : null,
    error : null
}
export const AuthSlice = createSlice({ 
    name: "authSlice-Func", 
    initialState,
    reducers: {
        logout : (state,action)=>{
            state.loading = false
            state.token = null;
            state.error = null
            localStorage.clear()
        }
    },

    extraReducers : (builder) =>{
        builder
        .addCase(login.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(login.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.token = action.payload
            state.role = action.payload?.user?.roleName
            state.passwordResetVerification = action?.payload?.user?.passwordResetVerification
            localStorage.setItem('tk',action?.payload?.user?.accessToken)
            localStorage.setItem('prv',action?.payload?.user?.passwordResetVerification)
            localStorage.setItem('role',action?.payload?.user?.roleName)

            // console.log(action.payload);
        })

        .addCase(login.rejected, (state,action)=>{
            state.loading = false;
            state.token = ""
            state.role = ""
            state.error = action.payload
        })

    }
});

export const {logout} = AuthSlice.actions

export default AuthSlice.reducer

