import { Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { sendEmail } from "../Components/Redux/Slice/Auth/auth"
import { useEffect, useState } from "react"
import { GrPrevious } from "react-icons/gr"
import AltLayout from "../Components/Alt-layout/altLayout"
import { useTranslation } from "react-i18next"

const ForgetPassword = () => {
    const {t,i18n} = useTranslation()
    const form = useForm()
    const {register,control,handleSubmit,formState} = form
    const {errors} = formState
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)
    const [showMessage, setshowMessage] = useState(false)
    
    const submitHandler = async (data)=>{
        // console.log(data);
        dispatch(sendEmail(data)).then((e)=>{
            // console.log(e);
            if(e?.payload?.mesage === 'SUCCESS'){
                setshowMessage(true)
                navigate('/login')
            } else{
                setshowMessage(false)
            }
        })
        setloading(true)

    }

    useEffect(() => {
        setTimeout(() => {
          setloading(false)
        }, 3000);
    }, [loading,showMessage])    

    return ( 
        <div className="h-full flex flex-col justify-between">
            <AltLayout>
                <article className="forget-password-wrapper">
                    <div className="w-full flex justify-center items-center">
                        
                        <div className="min-w-[350px] w-full md:w-[60%] lg:w-1/2 bg-white p-4 rounded-lg shadow-md">
                            <div className="flex items-center gap-x-3 ">
                                <div className='previous-page-icon text-2xl cursor-pointer' onClick={()=>navigate(-1)}>
                                    <GrPrevious />
                                </div>
                                <div className="w-full text-left verfication-email-title-wrapper capitalize font-normal text-[#333] text-xl font-playfair">Forgot Password</div>
                            </div>

                            <form onSubmit={handleSubmit(submitHandler)} className="mt-5">
                                
                                <div className="w-[85%] m-auto [&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:py-6 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                    <label>email address</label>
                                    <input type="email" id="email"
                                        {...register('email',{
                                            pattern:{
                                                value : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                                message : 'Wrong Email Format'
                                            },
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            }
                                        })} 
                                        // defaultValue={profile?.profile?.data?.email}
                                    />

                                    {errors?.email?.message ? <p className="capitalize text-sm text-danger">{errors?.email?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter your email</p>}
                                </div>

                                <div className="flex justify-center mt-7">
                                    <Button type="submit" disabled={loading} className="disabled:opacity-50 disabled:cursor-not-allowed text-white py-2 px-8 rounded-full bg-mainColor">Send</Button>
                                </div>
                            </form>

                            {showMessage ? <div className="w-full text-center text-mainGreen font-semibold mt-8 capitalize text-lg">please check your email</div> : null}
                        </div>
                    </div>
                </article>
            </AltLayout>
        </div>
    );
}

export default ForgetPassword;