import { LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import { BsFillEmojiFrownFill, BsFillEmojiHeartEyesFill, BsFillEmojiNeutralFill, BsFillEmojiSmileFill } from "react-icons/bs";
import { GiVomiting } from "react-icons/gi";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import DashReviewSlider from "./dashboardReviewSlider/dashReviewSlider";
import { useDispatch, useSelector } from "react-redux";
import { getRating, getRatingSummary } from "../Redux/Slice/Rating/rating";
import { getMyRestaurant } from "../Redux/Slice/MyRestaurant/myRestaurant";
import { useTranslation } from "react-i18next";


const Progress = ({ value, min, max,className }) => {
    
    const normalise = (value) => {
        if (isNaN(min) || isNaN(max) || max - min === 0) {
          return 0;
        }
        return ((value - min) * 100) / (max - min);
      };

  
    return (
      <>
        <LinearProgress className={`${className}`} variant="determinate" value={normalise(value)} />
      </>
    );
  };

const DashRating = () => {
    const {t,i18n} = useTranslation()

    const dispatch = useDispatch()
    const {rate} = useSelector(state=>state)
    const {myRestaurant} = useSelector(state=>state)

    let page = 1,
        size = 5
    
    const today = new Date()
    const getWeeks = today.setDate(today.getDate() - 20);
    const currentWeek = new Date(getWeeks)

    let selectedDate = {
        startDate : today,
        endDate : currentWeek
    }
    useEffect(() => {
        dispatch(getRating({page,size}))
        dispatch(getMyRestaurant())
        dispatch(getRatingSummary())
    }, [dispatch])

    // console.log(rate);

    

    return ( 
        <div className="w-full">
            <div className="flex flex-wrap justify-between items-center mb-2">
                <div className="dashboard-review-title-wrapper ">
                    <h2 className="font-semibold text-[15px] text-[#464E5F]">{t('dashboard.dash_rating.title')}</h2>
                    <span className="text-[12px] text-[#B5B5C3]">{rate?.ratingSummary?.data?.total} {t('dashboard.dash_rating.review')}</span>
                </div>

                <div className="review-stars-wraaper flex bg-[#FFF4DE] py-2 px-3 rounded-md [&>span]:text-xl">
                    {[...Array(5)].map((_,index)=>(
                        index < myRestaurant?.myRestaurant?.data?.averageRating ? <span className='text-mainYellow' key={index}><AiFillStar /></span> : <span className='text-[#DADADA]' key={index}><AiFillStar /></span>
                    ))}
                </div>
            </div>

            <div className="review-rating-bars-wrapper [&>div>div]:grow [&>div>span]:w-[80px] border-b">
                <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#5CAC7D]">
                    <span>{t('dashboard.dash_rating.rating.awesome')}</span>
                    <div>
                        {/* <LinearProgress aria-valuemax={10} className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#5CAC7D]" variant="determinate" value={awesome?.length} /> */}
                        <Progress value={rate?.ratingSummary?.data?.five} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#5CAC7D]' />
                    </div>
                    <p><BsFillEmojiHeartEyesFill /></p>
                </div>

                <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#D9AE56]">
                    <span>{t('dashboard.dash_rating.rating.good')}</span>
                    <div>
                        {/* <LinearProgress className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#D9AE56]" variant="determinate" value={good?.length} /> */}
                        <Progress value={rate?.ratingSummary?.data?.four} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#D9AE56]' />
                    </div>
                    <p><BsFillEmojiSmileFill /></p>
                </div>

                <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#FCA785]">
                    <span>{t('dashboard.dash_rating.rating.meh')}</span>
                    <div>
                        {/* <LinearProgress className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#FCA785]" variant="determinate" value={meh?.length} /> */}
                        <Progress value={rate?.ratingSummary?.data?.three} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#FCA785]' />
                    </div>
                    <p><BsFillEmojiNeutralFill /></p>
                </div>

                <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#FA5769]">
                    <span>{t('dashboard.dash_rating.rating.bad')}</span>
                    <div>
                        {/* <LinearProgress className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#FA5769]" variant="determinate" value={bad?.length} /> */}
                        <Progress value={rate?.ratingSummary?.data?.two} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#FA5769]' />
                    </div>
                    <p><BsFillEmojiFrownFill /></p>
                </div>

                <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#C6345C]">
                    <span>{t('dashboard.dash_rating.rating.terrible')}</span>
                    <div>
                        {/* <LinearProgress className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#C6345C]" variant="determinate" value={terrible?.length} /> */}
                        <Progress value={rate?.ratingSummary?.data?.one} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#C6345C]' />
                    </div>
                    <p><GiVomiting /></p>
                </div>
            </div>

            <div className="review-customer-wrapper mt-3 w-full">
                <DashReviewSlider />
            </div>
        </div>
    );
}

export default DashRating;