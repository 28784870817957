import { createSlice } from "@reduxjs/toolkit"

export const OverlaySlice = createSlice({
	name: "Overlay",
	initialState: {
		status:false,
        dateOverlay : false,
        orderNotify : false,
        pageOverlay : false
	},
	reducers: {
        openHandler : (state,action)=>{
            state.status = action.payload.headerDrop
            state.dateOverlay = action.payload.dateShow
            state.orderNotify = action.payload.orderNotification
            state.pageOverlay = action.payload.pageOverlay
        },
        closeHandler : (state,action)=>{
            state.status = false
            state.dateOverlay = false
            state.orderNotify = false
            state.pageOverlay = false

        }
    },
})
export const { openHandler, closeHandler } = OverlaySlice.actions
export default OverlaySlice.reducer
