import { Button, LinearProgress, Pagination } from "@mui/material";
import Reviews from "../Components/RatingComponents/review";
import { BsFillEmojiHeartEyesFill, BsFillEmojiSmileFill, BsFillEmojiNeutralFill, BsFillEmojiFrownFill } from "react-icons/bs";
import { GiVomiting } from 'react-icons/gi'
import { AiFillStar } from "react-icons/ai";
import { RatingSkeletons } from "../Components/Skeleton/skeleton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRating, getRatingSummary } from "../Components/Redux/Slice/Rating/rating";
import { getMyRestaurant } from "../Components/Redux/Slice/MyRestaurant/myRestaurant";
import { DateRangePicker } from 'react-date-range';
import { openHandler } from "../Components/Redux/Slice/common/overlaySlice";
import Overlay from "../Components/Common/overlay";
import { useTranslation } from "react-i18next";
import { resetPage } from "../Components/Redux/Slice/ResetPagination/resetPagination";
import dayjs from "dayjs";

const Progress = ({ value, min, max, className }) => {

    const normalise = (value) => {
        if (isNaN(min) || isNaN(max) || max - min === 0) {
            return 0;
        }
        return ((value - min) * 100) / (max - min);
    };


    return (
        <>
            <LinearProgress className={`${className}`} variant="determinate" value={normalise(value)} />
        </>
    );
};

const Rating = () => {
    const { t, i18n } = useTranslation()
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(5)
    const [selectedDate, setSelectedDate] = useState(null); // for storing the selected date from date picker
    const { currentPage, toggle } = useSelector((state) => state?.resetPagination);

    const dispatch = useDispatch()
    const { rate } = useSelector(state => state)
    const { myRestaurant } = useSelector(state => state)

    useEffect(() => {
        dispatch(getRating({ page, size }))
        dispatch(getMyRestaurant())
    }, [])

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    // date range picker
    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    let fromDate = new Date(date[0]?.startDate)
    let toDate = new Date(date[0]?.endDate)

    const { dateOverlay } = useSelector(state => state.overlay)

    const dateRangeToggle = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }
    const handleDateRangeChange = (e) => {
        setdate([e.selection])
        let dateRange = e?.selection
        let fromDate = dayjs(dateRange?.startDate).format('YYYY-MM-DDTHH:mm:ss')
        let toDate = dayjs(dateRange?.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        let selectedDate = { fromDate: fromDate, toDate: toDate }
        setSelectedDate(selectedDate)

        dispatch(getRating({ page, size, selectedDate }))
        dispatch(getRatingSummary())
        dispatch(resetPage())

        // console.log(selectedDate);
    }

    // console.log(rate);
    const handlePageChange = (e, page) => {
        setpage(page);
    };

    const { allRates } = useSelector(state => state?.rate)
    // console.log(allRates);

    useEffect(() => {
        dispatch(getRating({ page, size, selectedDate }))
        dispatch(getRatingSummary())
    }, [dispatch, page, size, selectedDate])

    useEffect(() => {
        // console.log(rate?.ratingSummary?.data);
    }, [rate?.ratingSummary?.data])

    // console.log(Math.ceil(myRestaurant?.myRestaurant?.data?.averageRating))

    return (
        <article className="rating-wrapper pb-4">
            <section className="menu-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize" >{t('rating.title')}</h1>
                <div className="date-button-wrapper mt-3">
                    {/* <Button onClick={dateRangeToggle} className=" button-range capitalize rounded-md border-none bg-white text-[#333] font-semibold py-1">Select Date</Button> */}
                    <Button className='bg-white text-[#333] font-semibold rounded-md' onClick={dateRangeToggle}>
                        <button className='flex items-center'> {date[0]?.startDate && date[0]?.endDate ? new Date(fromDate.getTime() + (fromDate?.getTimezoneOffset() * -60000)).toISOString().slice(0, 10) + " - " + new Date(toDate.getTime() + (toDate?.getTimezoneOffset() * -60000)).toISOString().slice(0, 10) : localStorage.getItem('i18nextLng') === 'ar' ? 'اختر التاريخ' : 'Select Date'}</button>
                    </Button>
                    {dateOverlay ?
                        <div dir='ltr' className="absolute z-[9999] left-[40%]  -translate-x-1/2 top-8 bg-white p-4 rounded-lg">
                            <DateRangePicker
                                onChange={handleDateRangeChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={date}
                                direction="vertical"

                            />
                        </div> : null}
                </div>
            </section>

            <div className="rating-sections-wrapper flex flex-wrap xl:flex-nowrap justify-between gap-4 mt-5">
                <section className="rating-left-section-wrapper basis-full xl:basis-3/4">
                    <div className="bg-white py-4 px-3 rounded-md ">
                        <section className="rating-title-wrapper flex justify-between">
                            <div>
                                <h2 className="text-[#464E5F] mb-2 font-bold text-lg">{t('rating.sub_title')}</h2>
                                <p className="text-[11px] text-[#B5B5C3]">{t('rating.helper')}</p>
                            </div>
                        </section>

                        <section className="review-wrapper mt-10">
                            {
                                rate?.loading ? [...Array(5)].map((e, index) => <RatingSkeletons key={index} />)
                                    :
                                    rate?.rate?.data?.map(item => (
                                        <Reviews data={item} key={item?.id} t={t} />
                                    ))
                            }
                        </section>

                        <section className="pagination-wrapper">
                            <Pagination dir='ltr' count={rate?.rate?.meta?.totalPages} defaultPage={currentPage} onChange={handlePageChange} />
                        </section>
                    </div>
                </section>

                <section className="rating-left-section-wrapper basis-full xl:basis-1/4">
                    <div className="bg-white pt-4 rounded-md ">
                        <div className="px-3 rating-progress-title">
                            <h3 className="text-[#464E5F] font-bold text-lg">{t('rating.title')}</h3>
                            <p className="text-[#B5B5C3] text-[12px] mt-1">{rate?.ratingSummary?.data?.total} {t('rating.review')}</p>
                        </div>

                        <div className="px-3 rating-progress-wrapper mt-8 [&>div>p>svg]:text-xl [&>div>span]:w-[30%] [&>div>div]:w-[55%]">

                            <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#5CAC7D]">
                                <span>{t('dashboard.dash_rating.rating.awesome')}</span>
                                <div>
                                    {/* <LinearProgress aria-valuemax={10} className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#5CAC7D]" variant="determinate" value={awesome?.length} /> */}
                                    <Progress value={rate?.ratingSummary?.data?.five} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#5CAC7D]' />
                                </div>
                                <p><BsFillEmojiHeartEyesFill /></p>
                            </div>

                            <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#D9AE56]">
                                <span>{t('dashboard.dash_rating.rating.good')}</span>
                                <div>
                                    {/* <LinearProgress className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#D9AE56]" variant="determinate" value={good?.length} /> */}
                                    <Progress value={rate?.ratingSummary?.data?.four} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#D9AE56]' />
                                </div>
                                <p><BsFillEmojiSmileFill /></p>
                            </div>

                            <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#FCA785]">
                                <span>{t('dashboard.dash_rating.rating.meh')}</span>
                                <div>
                                    {/* <LinearProgress className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#FCA785]" variant="determinate" value={meh?.length} /> */}
                                    <Progress value={rate?.ratingSummary?.data?.three} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#FCA785]' />
                                </div>
                                <p><BsFillEmojiNeutralFill /></p>
                            </div>

                            <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#FA5769]">
                                <span>{t('dashboard.dash_rating.rating.bad')}</span>
                                <div>
                                    {/* <LinearProgress className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#FA5769]" variant="determinate" value={bad?.length} /> */}
                                    <Progress value={rate?.ratingSummary?.data?.two} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#FA5769]' />
                                </div>
                                <p><BsFillEmojiFrownFill /></p>
                            </div>

                            <div className="flex items-center justify-between capitalize gap-x-2 mb-6 text-[#C6345C]">
                                <span>{t('dashboard.dash_rating.rating.terrible')}</span>
                                <div>
                                    {/* <LinearProgress className="rounded-md h-2 bg-[#eee] [&>span]:bg-[#C6345C]" variant="determinate" value={terrible?.length} /> */}
                                    <Progress value={rate?.ratingSummary?.data?.one} min={0} max={rate?.ratingSummary?.data?.total} className='rounded-md h-2 bg-[#eee] [&>span]:bg-[#C6345C]' />
                                </div>
                                <p><GiVomiting /></p>
                            </div>

                        </div>

                        <div className="px-3 w-full rounded-t-md bg-[#FFF4DE] p-6 text-center">
                            <div className="flex items-center justify-around">
                                <p className="text-3xl text-mainYellow font-bold">{myRestaurant?.myRestaurant?.data?.averageRating}</p>
                                <div className="flex">{[...Array(Math.floor(myRestaurant?.myRestaurant?.data?.averageRating) || 0)].map((item, index) => <AiFillStar key={index} className="text-mainYellow text-2xl" />)}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {rate?.loading ? <Overlay /> : null}
        </article>
    );
}

export default Rating;