import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getMyRestaurant = createAsyncThunk("getMyRestaurantFun", async(_, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await axios.get(`/my-restaurant`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editMyRestaurant = createAsyncThunk("editMyRestaurantFun", async(values, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await toast.promise(axios.put(`/my-restaurant`,values),{
            pending: 'loading...',
            success: 'Restaurant Edited Successfully',
        },{toastId : "MyRestaurantToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const toggleRestaurantStatus = createAsyncThunk("toggleRestaurantStatusFun", async(value, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/status`,value),{
            pending: 'loading...',
            success: 'Restaurant Status Changed Successfully',
        },{toastId : "MyRestaurantStatusToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const setWorkingHours = createAsyncThunk("setWorkingHoursFun", async(value, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/set-working-hours`,value,{
            headers : {
                'Content-Type': 'application/json',
            }
        }),{
            pending: 'loading...',
            success: 'Form Submitted Successfully',
        },{toastId : "FormSubmitTimeToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getDeliveryTypes = createAsyncThunk("getDeliveryTypesFun", async(_, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await axios.get(`/delivery-types`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

const initialState = {
    loading: false,
    myRestaurant : [],
    workingHours : [],
    deliveryTypes : [],
    error : null
}
export const MyRestaurantSlice = createSlice({ 
    name: "MyRestaurant-Slice", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getMyRestaurant.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getMyRestaurant.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.myRestaurant = action.payload
            // console.log(action.payload);
        })

        .addCase(getMyRestaurant.rejected, (state,action)=>{
            state.loading = false;
            state.myRestaurant = ""
            state.error = action.payload
        })

        .addCase(editMyRestaurant.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(editMyRestaurant.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.myRestaurant = action.payload
            // console.log(action.payload);
        })

        .addCase(editMyRestaurant.rejected, (state,action)=>{
            state.loading = false;
            state.myRestaurant = ""
            state.error = action.payload
        })

        .addCase(setWorkingHours.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(setWorkingHours.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.workingHours = action.payload
            // console.log(action.payload);
        })

        .addCase(setWorkingHours.rejected, (state,action)=>{
            state.loading = false;
            state.workingHours = ""
            state.error = action.payload
        })

        .addCase(getDeliveryTypes.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getDeliveryTypes.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.deliveryTypes = action.payload
            // console.log(action.payload);
        })

        .addCase(getDeliveryTypes.rejected, (state,action)=>{
            state.loading = false;
            state.deliveryTypes = ""
            state.error = action.payload
        })


    }
});

// export const {} = MyRestaurantSlice.actions

export default MyRestaurantSlice.reducer

