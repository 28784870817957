import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const addPartner = createAsyncThunk("addPartnerFun", async(values, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await toast.promise(axios.post(`https://api-dev.takein.sa/public-app/restaurants/restaurant-sign-up`,values),{
            pending: 'loading...',
            success: 'Partner Added Successfully, Wait for Approval',
        },{toastId : "Partner Request"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


export const MultiStepForm = createSlice({
	name: "MultiStepFormSlice",
	initialState: {
		values : {},
		partner : '',
        status : {
            stepOne:false,
            stepTwo:false,
            stepThree:false,
            success : false
        },
        success : false
	},
	reducers: {
        saveData : (state,action)=>{
            state.values = {...state?.values,...action.payload}
        },
        toggleStatus : (state,action)=>{
            // console.log(action?.payload);
            state.status ={...state?.status,...action?.payload}
        },
        displaySuccess : (state,action)=>{
            state.success = action.payload
        }
    },
	extraReducers : (builder) =>{
        builder
        .addCase(addPartner.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addPartner.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.partner = action.payload
            // console.log(action.payload);
        })

        .addCase(addPartner.rejected, (state,action)=>{
            state.loading = false;
            state.partner = ""
            state.error = action.payload
        })


    }
})
export const { saveData, toggleStatus,displaySuccess } = MultiStepForm.actions
export default MultiStepForm.reducer
