import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getUsers = createAsyncThunk("UsersFuncs", async({page,row,searchValue,col,dir}, {rejectWithValue,dispatch})=>{
    let colCheck;

    if (col) {
        colCheck = 
            (col === 'Created at' || col === 'تم انشاءه فى' ) ? 'created_at' 
          : (col === 'Last Login' || col === 'اخر تسجيل دخول') ? 'last_login' 
          : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
          : (col === 'Name' || col === 'الاسم') ? 'full_name' 
          :  col.replace(/\s+/g, '');
    }
    
    const url = !col ? `/users?page=${page ? page : 1}&size=${row ? row : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/users?page=${page ? page : 1}&size=${row ? row : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${searchValue ?`&search=${searchValue}`: ''}`;
    
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addUser = createAsyncThunk("addUserFuncs", async(values, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await toast.promise(axios.post(`/users`,values),
        {
            pending: 'loading...',
            success: 'User Added Successfully 👌',
            // error: 'Promise rejected 🤯'
        },{toastId : "UserAddingToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    users : [],
    error : null
}
export const UsersSlice = createSlice({ 
    name: "UsersSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getUsers.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getUsers.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.users = action.payload
            // console.log(action.payload);
        })

        .addCase(getUsers.rejected, (state,action)=>{
            state.loading = false;
            state.users = ""
            state.error = action.payload
        })
        .addCase(addUser.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addUser.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(addUser.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

    }
});

// export const {} = UsersSlice.actions

export default UsersSlice.reducer

