import { Menu, MenuItem } from "@mui/base";
import { ListItemButton } from "@mui/material";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";

function ActionCell( row ) {
    // console.log(row);
    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = ()=>setopenModal(true)
    const handleCloseModal = ()=>setopenModal(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let page = 1,
        size = 10
   
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0} }}
                className='shadow-md p-0'
                sx={{'& .css-6hp17o-MuiList-root-MuiMenu-list' : {padding:0}}}
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    <MenuItem className='text-mainColor hover:bg-[#b3c0f742]' onClick={handleOpenModal}><FaEdit /> Edit</MenuItem>
                </div>
            </Menu>

        </div>
    );
}

const Accounts = () => {
    const [open, setopen] = useState(false)
    const handleOpen = ()=>setopen(true)
    const handleClose = ()=>setopen(false)
    const dispatch = useDispatch()
    const [activeRowData,setactiveRowData] = useState(null)

    //data table section
    const customStyles = {
            headRow: {
                style: {
                border: 'none',
                backgroundColor : '#eee'
                },
            },
            headCells: {
                style: {
                    color: '#202124',
                    fontSize: '15px',
                    position : 'relative',
                    textTransform : 'capitalize',
                    padding : '5px'
                },
            },
            rows: {
                style:{
                    cursor : 'pointer',
                },
                highlightOnHoverStyle: {
                    backgroundColor: '#442b7e12',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '5px',
                    outline: '1px solid #FFFFFF',
                    whiteSpace: 'wrap',
                },
                padding : '10px'
            },
            pagination: {
                style: {
                    border: 'none',
                },
            },
            cells: {
                style:{
                    marginBottom : '10px',
                    padding : '0px 5px'
                }
            }
        };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            width:'120px'
        },
        {
            name: 'Account holder name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Balance',
            selector: row => row.balance,
            sortable: true,
        },
        {
            name: 'Created at',
            selector: row => row.createdAt,
            sortable: true,
        },
        {
            name: 'type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Actions',
            allowOverflow: true,
            button : true,
            cell: ActionCell
                
        },
    ];
    
    const data = [
        {id:1025,name:'george',balance:5000,createdAt:'20-10-2025',type:'cash',status:'active'},
        {id:1045,name:'alex',balance:25622,createdAt:'20-11-2020',type:'cash',status:'active'},
        {id:1013,name:'justine',balance:78856,createdAt:'18-01-2008',type:'visa',status:'active'},
        {id:1005,name:'max',balance:899966,createdAt:'17-05-2010',type:'cash',status:'disactive'},
        {id:1155,name:'samantha',balance:12,createdAt:'10-10-2010',type:'visa',status:'active'},
        {id:2565,name:'rolex',balance:963,createdAt:'20-10-2020',type:'visa',status:'hold'},
        {id:8565,name:'hunter',balance:452,createdAt:'20-10-2025',type:'cash',status:'active'},
    ]

    // open calander overlay

    // useEffect(() => {
    // //   console.log(activeRowData);
    // }, [activeRowData])

    // const [page, setpage] = useState(1)
    // const [size, setsize] = useState(10)

    // useEffect(() => {
    //     dispatch(getOffers({page,size}))
    //     // dispatch(getOrdersTypes())
    // }, [dispatch, page, size])

    // const handlePageChange = page => {
    //     setpage(page);
    // };

    // const handleRowChange = rows => {
    //     setsize(rows);
    // };
    // useEffect(() => {
    //     dispatch(getOffers({page,size}))
    // }, [dispatch, page,size])

    // // console.log(offers);

    // const options = { day: 'numeric', month: 'short', year: 'numeric' };

    // const handleRemoteSort = (col,dir)=>{
    //     dispatch(getOffers({page,size,col,dir}))
    //     // console.log(col);
    // }
    
    return ( 
        <article className="offers-wrapper pb-5">
            <section className="order-title">
                <h1 className="text-[#333] font-extrabold text-3xl capitalize">Accounts</h1>
            </section>

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationPerPage = {5}
                    paginationRowsPerPageOptions = {[5,50,100]}
                    // paginationServer
                    // paginationTotalRows={10}
                    // onChangePage={handlePageChange}
                    // onChangeRowsPerPage={handleRowChange}
                    customStyles={customStyles}
                    highlightOnHover
                    selectableRows
                    selectableRowsHighlight
                    // onSelectedRowsChange={(e)=>console.log(e)}
                    onRowClicked={(row)=>setactiveRowData(row)}
                    // sortServer
                    // onSort={handleRemoteSort}
                />

                {/* {offers?.loading ?
                <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                    <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                </div> : null} */}
            </section>

            {activeRowData ? 
            <section className='offer-preview-wrapper mt-5'>
                <div className='offer-preview-title font-semibold mb-3 text-[#464E5F] capitalize'>
                    <h2>Transactions</h2>
                </div>

                <div> 
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationPerPage = {5}
                        paginationRowsPerPageOptions = {[5,50,100]}
                        // paginationServer
                        // paginationTotalRows={10}
                        // onChangePage={handlePageChange}
                        // onChangeRowsPerPage={handleRowChange}
                        customStyles={customStyles}
                        highlightOnHover
                        selectableRows
                        selectableRowsHighlight
                        // onSelectedRowsChange={(e)=>console.log(e)}
                        onRowClicked={(row)=>setactiveRowData(row)}
                        // sortServer
                        // onSort={handleRemoteSort}
                    />
                </div>
            </section> : null }

        </article>
    );
}

export default Accounts;