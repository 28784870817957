import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getCategories = createAsyncThunk("getCategoriesFuncs", async(_, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/my-restaurant/categories`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addCategories = createAsyncThunk("addCategoriesFuncs", async(value, {rejectWithValue,dispatch})=>{
    try {
        const res = await toast.promise(axios.post(`/my-restaurant/categories`,value),{
            pending: 'loading...',
            success: 'Category Added Successfully 👌',
            },{toastId : "AddCategoryToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editCategories = createAsyncThunk("editCategoriesFuncs", async({editID,data}, {rejectWithValue,dispatch})=>{
    // console.log(editID,data);
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/categories/${editID}`,data),{
            pending: 'loading...',
            success: 'Category Edited Successfully 👌',
            },{toastId : "EditCategoryToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const deleteCategories = createAsyncThunk("deleteCategoriesFuncs", async(id, {rejectWithValue,dispatch})=>{
    // console.log(id);
    try {
        const res = await toast.promise(axios.delete(`/my-restaurant/categories/${id}`),{
            pending: 'loading...',
            success: 'Category Deleted Successfully 👌',
            },{toastId : "DeleteCategoryToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getSingleCategory = createAsyncThunk("getSingleCategory", async(id, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/my-restaurant/categories/${id}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

// export const getMenuItemById = createAsyncThunk("getMenuItemByIdFuncs", async(id, {rejectWithValue,dispatch})=>{

//     try {
//         const res = await axios.get(`/my-restaurant/menu-items/${id}`)
//         // console.log(res);
//         return res.data

//     } catch(error){
//         // console.log(error.response);
//         if (error.response && error.response.data) {
//             if(Array?.isArray(error.response.data.message)){
                // error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            // } else{
            //     dispatch(errorToast(error.response.data.message))
            // }
//             return rejectWithValue(error.response.data.message)
//         } else if(navigator.onLine === false){
//             dispatch(errorToast('Check Internet Connection'))
//         }
        
//         else {
//             return rejectWithValue(error.message)
//         }
//     }
// })

export const addMenuItem = createAsyncThunk("addMenuItemFuncs", async(formData, {rejectWithValue,dispatch})=>{

    // console.log({formData});
    try {
        const res = await toast.promise(axios.post(`/my-restaurant/menu-items`,{...formData,allBranches : true}),{
            pending: 'loading...',
            success: 'Item Added Successfully 👌',
            },{toastId : "AddMenuItemToast"
        })
        // console.log(res);
        return res.data

    } catch(error){
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editMenuItem = createAsyncThunk("editMenuItemFuncs", async({id,formData}, {rejectWithValue,dispatch})=>{

    // console.log({formData});
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/menu-items/${id}`,{...formData,allBranches : true}),{
            pending: 'loading...',
            success: 'Item Updated Successfully 👌',
            },{toastId : "EditMenuItemToast"
        })
        // console.log(res);
        return res.data

    } catch(error){
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const deleteMenuItem = createAsyncThunk("deleteMenuItemFuncs", async(id, {rejectWithValue,dispatch})=>{
    try {
        const res = await toast.promise(axios.delete(`/my-restaurant/menu-items/${id}`),{
            pending: 'loading...',
            success: 'Item Deleted Successfully 👌',
            },{toastId : "DeleteMenuItemToast"
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getMostSellingItems = createAsyncThunk("getMostSellingItemsFuncs", async(_, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/my-restaurant/menu-items/top`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getMenuItemsList = createAsyncThunk("getMenuItemsListFuncs", async(_, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/my-restaurant/menu-items/list`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const toggleActivationMenuItem = createAsyncThunk("toggleActivation", async({id,status}, {rejectWithValue,dispatch})=>{

    try {
        const res = await toast.promise(axios.put(`/my-restaurant/menu-items/${id}/${status}`),{
            pending : "Loading...",
            success : `Menu item ${status}d successfully`
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})



const initialState = {
    loading: false,
    categories : [],
    singleCategory : [],
    mostSelling : [],
    menuItemsList : [],
    error : null
}

export const CategorySlice = createSlice({ 
    name: "CategorySlice-Func", 
    initialState,
    reducers: {
        
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getCategories.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getCategories.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.categories = action.payload
            // console.log(action.payload);
        })

        .addCase(getCategories.rejected, (state,action)=>{
            state.loading = false;
            state.categories = []
            state.error = action.payload
        })

        .addCase(getSingleCategory.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getSingleCategory.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.singleCategory = action.payload
            // console.log(action.payload);
        })

        .addCase(getSingleCategory.rejected, (state,action)=>{
            state.loading = false;
            state.singleCategory = []
            state.error = action.payload
        })
        
        .addCase(addMenuItem.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addMenuItem.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
        })

        .addCase(addMenuItem.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(editMenuItem.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(editMenuItem.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
        })

        .addCase(editMenuItem.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(deleteMenuItem.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(deleteMenuItem.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(deleteMenuItem.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(getMostSellingItems.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getMostSellingItems.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.mostSelling = action.payload
            // console.log(action.payload);
        })

        .addCase(getMostSellingItems.rejected, (state,action)=>{
            state.loading = false;
            state.mostSelling = []
            state.error = action.payload
        })

        .addCase(getMenuItemsList.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getMenuItemsList.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.menuItemsList = action.payload
            // console.log(action.payload);
        })

        .addCase(getMenuItemsList.rejected, (state,action)=>{
            state.loading = false;
            state.menuItemsList = []
            state.error = action.payload
        })

        .addCase(toggleActivationMenuItem.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(toggleActivationMenuItem.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(toggleActivationMenuItem.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })


    }
});

export default CategorySlice.reducer

