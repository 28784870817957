// paginationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPage: 1,
    toggle : false,
    search : true,
    sort : null
};

const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.currentPage = action.payload;
        },
        resetPage: (state) => {
            state.toggle = !state?.toggle;
            state.currentPage = 1
        },
        resetSearch: (state) => {
            state.search = !state.search;
        },
        resetSort : (state,action) => {
            state.sort = action?.payload?.sort
        }
    },
});

export const { setPage, resetPage, resetSearch,resetSort } = paginationSlice.actions;
export default paginationSlice.reducer;
