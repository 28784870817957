import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PartnerMap from "./partnerMap";
import { addPartner, displaySuccess, toggleStatus } from "../Redux/Slice/MultiStepForm/multiStepFormSlice";

const Final = ({ nextStep, prevStep}) => {

    const { handleSubmit, register, setValue, getValues,formState,setError } = useForm();
    const {errors} = formState

    const values = useSelector(state=>state?.multiStepForm)
    const dispatch = useDispatch()
    const [isSubmit,setisSubmit] = useState(false)

    // handle map component

    const handleCallbackSubmit = (status) =>{
        setisSubmit(status)
    }

    useEffect(() => {
        if(isSubmit){
            dispatch(addPartner(values?.values)).then( (e)=>{
                if(e?.payload?.message === 'SUCCESS'){
                    dispatch(toggleStatus({stepThree:true}))
                    dispatch(displaySuccess(true))
                }
            } )
        }
    }, [isSubmit])
    
    // console.log(values);
    return (
        <div>
            <div className="form flex flex-wrap gap-y-0 md:gap-x-1 xl:gap-x-1 justify-between [&>div>label]:block [&>div>label]:capitalize [&>div>label]:text-sm [&>div>label]:text-[#515151] [&>div>label]:font-semibold [&>div>label]:mb-1 [&>div>div>input]:w-full [&>div>div>input]:py-3 [&>div>div>input]:px-2 [&>div>div>input]:rounded-md [&>div>div>input]:bg-[#EEF1F5]">
                <div className="basis-full">
                    <label htmlFor="mainBranchAddress">Restaurant address</label>
                </div>

                <div className="basis-full">
                    <PartnerMap submit={handleCallbackSubmit} loading={values?.loading} prevStep={()=>prevStep()} />
                </div>

            </div>
        </div>
    );
};

export default Final;
  