import { GrNext, GrPrevious } from 'react-icons/gr';
import aboutLogo from '../Assets/imgs/aboutus.svg'
import logo from '../Assets/imgs/collapse-logo.svg'
import linth from '../Assets/imgs/Linth.svg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
const AboutUs = () => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation()

    const changeLanguage = (lang)=>{
        i18n.changeLanguage(lang?.target.value)
        // console.log(lang?.target.value);
    }

    return ( 
        <article className="terms-wrapper h-screen" dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}>
            <div className='flex w-full h-full'>
                <section className="left-sec-wrapper min-w-[200px] max-w-[300px] h-full relative bg-mainColor">
                    <div className='takeIn-logo-wrapper text-center w-full absolute left-1/2 top-[25%] translate-x-[-50%]'>
                        <img src={logo} alt='takinIN-logo' className='w-28 h-28 m-auto' />
                    </div>

                    <div className='takeIn-linth-wrapper text-center w-full absolute left-0 bottom-0'>
                        <img src={linth} alt='takinIN-logo' className='w-full' />
                    </div>
                </section>

                <section className='right-sec-wrapper grow h-full'>
                    <div className='terms-content-wrapper pt-10 h-full px-6'>
                        <div className={`terms-title-wrapper capitalize flex gap-x-3 items-center justify-between`}>
                            <div className='flex gap-x-3 items-center'>
                                <div className='previous-page-icon text-2xl cursor-pointer' onClick={()=>navigate(-1)}>
                                    {localStorage?.getItem('i18nextLng') === 'ar' ? <GrNext /> : <GrPrevious />}
                                </div>
                                <h2 className='font-normal text-xl text-[#464E5F] font-playfair'>{t('about.title')}</h2>
                            </div>

                            <div>
                                <Select 
                                    className="bg-mainColor text-white [&>div]:p-2 w-10 h-10 text-center [&>svg]:hidden text-sm" 
                                    sx={{'& fieldset': {border:0}}}
                                    defaultValue={(localStorage?.getItem('i18nextLng') === 'en-US' || localStorage?.getItem('i18nextLng') === 'en' || !localStorage.getItem('i18nextLng')) ? 'en' : 'ar'} 
                                    onChange={(lang)=>changeLanguage(lang)}
                                >
                                    <MenuItem value={'en'}>EN</MenuItem>
                                    <MenuItem value={'ar'}>AR</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className={`mt-5 bg-white rounded-lg px-3 py-5 flex ${localStorage?.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'} gap-x-4 w-full h-[90%] items-start`}>
                            <section className="content-logo-wrapper basis-[35%] my-auto">
                                <img src={aboutLogo} alt='terms-reading' className='w-full' />
                            </section>

                            <section className='content-text-wrapper text-[#333333] basis-[65%]'>
                                <h2 className='capitalize font-bold text-xl tracking-wider'>{t('about.title')}</h2>

                                <p className='max-w-[90%] mt-5 text-sm tracking-wide capitalize'>
                                    {t('about.body')}
                                </p>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </article>
     );
}

export default AboutUs;