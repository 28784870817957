import React, { useState,useEffect } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import './App.css';
import Routing from './Components/Routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { closeHandler } from './Components/Redux/Slice/common/overlaySlice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-phone-input-2/lib/style.css'

import axios from 'axios'

function App() {
  
  const [navCollapse, setnavCollapse] = useState(false)
  const {auth} = useSelector(state=>state)
  const toggleNavHanlder = ()=>{
    let toggle = navCollapse
    setnavCollapse(!toggle)
  }

  const {status,dateOverlay,orderNotify} = useSelector(state => state.overlay)
  const dispatch = useDispatch()
  const token = localStorage.getItem('tk') ? localStorage.getItem('tk') : null
  // if(process.env.REACT_APP_NODE_ENV === 'development'){
  //   axios.defaults.baseURL = 'https://api.takein.sa/partner-app'
  // } else if(process.env.REACT_APP_NODE_ENV === 'staging'){
  //   axios.defaults.baseURL = 'https://api-staging.takein.sa/partner-app'
  // } else{
  //   axios.defaults.baseURL = 'https://api-develop.takein.sa/partner-app'
  // }
  if(window.location.hostname === 'partner.takein.sa'){
    axios.defaults.baseURL = 'https://api.takein.sa/partner-app'
  } else if(window.location.hostname === 'partner-staging.takein.sa'){
    axios.defaults.baseURL = 'https://api-staging.takein.sa/partner-app'
  } else if (window.location.hostname === 'partner-develop.takein.sa'){
    axios.defaults.baseURL = 'https://api-develop.takein.sa/partner-app'
  } else if(window.location.hostname === 'localhost'){
    axios.defaults.baseURL = 'https://api-develop.takein.sa/partner-app'
  }
  // console.log('axios.defaults.baseUrl', axios.defaults.baseURL)
  // console.log('process.env.REACT_APP_GOOGLE_MAP_KEYS',process.env.REACT_APP_GOOGLE_MAP_KEYS )
  // console.log('process.env.REACT_APP_NODE_ENV', process.env.REACT_APP_NODE_ENV)
  // console.log('process.env', process.env)
	axios.defaults.headers.post["Content-Type"] = "application/json"
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`

  const navigate = useNavigate()
  const location = useLocation()

    // console.log(localStorage.getItem('tk') && location.pathname === '/' && (auth?.passwordResetVerification === true));

  useEffect(() => {
        // if(localStorage.getItem('tk') && location.pathname === '/' && (localStorage.getItem('prv') && localStorage.getItem('prv') === 'true')){
        //     navigate('/dashboard/home',{ replace: true })
        // } else if(auth?.passwordResetVerification === true){
        //     navigate('/firstnewpassword')
        // } else if((!localStorage.getItem('tk') || localStorage.getItem('tk') === 'undefined') && location.pathname === '/'){
        //   navigate('/login')
        // }
        console.log('Up and Running !')
        if( localStorage.getItem('tk') && location.pathname === '/' && localStorage.getItem('prv') === 'true'){
            navigate('/dashboard/home',{ replace: true })
        } else if(localStorage.getItem('tk') && localStorage.getItem('prv') === 'false'){
          navigate('/firstnewpassword',{ replace: true })
        } else if((!localStorage.getItem('tk') || localStorage.getItem('tk') === 'undefined') && location.pathname === '/'){
            navigate('/login')
        }
    }, [auth?.passwordResetVerification])

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear()
        navigate('/login')
      }
      return Promise.reject(error);
    }
  );

  // useEffect(() => {
  //   if(!localStorage.getItem('i18nextLng')){
  //     localStorage.setItem('i18nextLng','en')
  //   }
  // }, [localStorage.getItem('i18nextLng')])
  
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={8500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        limit={8}
      />
      
        <article className="TakeIn-Partner overflow-hidden h-screen bg-[#F2F0F6] relative">
            <main className='h-full overflow-y-auto'>
              <Routing />
            </main>

          {status || dateOverlay || orderNotify ? <section onClick={()=>dispatch(closeHandler())} className='overlay backdrop-blur-sm bg-[rgba(0,0,0,.01)] absolute top-0 bottom-0 left-0 right-0 z-[99]'></section> : null}
        </article>
    </>
  );
}

export default App;
