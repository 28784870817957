import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const BranchAdminRoles = () => {
    const location = useLocation()
    const auth = useSelector(state=>state.auth)
    // console.log(auth?.role === 'BRANCH_ADMIN');
    // console.log(location.pathname === '/dashboard/home');
    return ( 
        // (auth?.passwordResetVerification === true || ( localStorage.getItem('prv') && localStorage.getItem('prv') === 'true' )) ? <Outlet /> : <Navigate to='/firstnewpassword' state={{from:location}} replace />
        ((auth?.role === 'BRANCH_ADMIN' || ( localStorage.getItem('role') === 'BRANCH_ADMIN' )) && (location.pathname === '/dashboard/home' || location.pathname === '/')) ? 

            <Navigate to='/dashboard/menu' state={{from:location}} replace /> 
                : 
            (auth?.role === 'BRANCH_ADMIN' || ( localStorage.getItem('role') === 'BRANCH_ADMIN' )) ? 
                
                <Navigate to='/dashboard/unauthorized' state={{from:location}} replace />
                
                : <Outlet />
    );
}

export default BranchAdminRoles;