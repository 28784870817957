import { Button } from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    
} from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../Redux/Slice/MultiStepForm/multiStepFormSlice";
import { getMapKey } from "../Redux/Slice/common/mapSlice";
import { useTranslation } from "react-i18next";

const libraries = ["places"];

const containerStyle = {
    width: "100%",
    height: "350px",
};

const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };


const PartnerMap = ({submit,loading,prevStep}) => {
  const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const [center,setCenter] = useState({
      lat : 24.711237,
      lng :  46.684593
  })
    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [address, setAddress] = useState(null);
    const [error, seterror] = useState(null);
    const {key} = useSelector(state=>state?.map)

  //   useEffect(() => {
  //     dispatch(getMapKey())
  // }, [])

    const { isLoaded } = useLoadScript({
        googleMapsApiKey : key?.data?.GOOGLE_MAP_KEYS,   
        libraries,
    });

    const onDragHandler = async (event)=>{
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarkerPosition({ lat, lng });

        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key?.data?.GOOGLE_MAP_KEYS}`
          );
          const data = await response.json();
          // console.log(data);
          if (data.results && data.results.length > 0) {
            setAddress(data.results[0].formatted_address);
            seterror(null)
          }
        } catch (error) {
          console.error("Error:", error);
        }
    }

    const handleSelect = async (selectedAddress) => {
      try {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);
        setAddress(selectedAddress);
        setMarkerPosition(latLng);
        setCenter(latLng)
        seterror('')
      } catch (error) {
        console.error("Error:", error);
      }
    };
    useEffect(() => {
        if (isLoaded) {
          setMap(
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={14}
              options={options}
            >
              {center && (<Marker
                position={center}
                draggable={true}
                onDragEnd={(event) =>onDragHandler(event) }
              />)}
            </GoogleMap>
          );
        }
      }, [isLoaded, markerPosition]);

      // console.log(address);
      // console.log(markerPosition);
      
      const submitFormData = async (e) => {
        e.preventDefault()
        let values = {'mainBranchAddress' : address,
                      'mainBranchCoordinates' : 
                      {"type": "Point","coordinates": [markerPosition?.lat,markerPosition?.lng],}}

          if(values && Object.keys(values).length !== 0){
            if(values?.mainBranchCoordinates?.coordinates !== null && (values?.mainBranchAddress !== null && values?.mainBranchAddress !== '')){
              await dispatch(saveData(values))
              submit(true)
            } else {
              seterror('enter a valid address')
              submit(false)
            }
        } else {
          seterror('this field is required')
        }
    };

    // console.log(loading);

      return ( 
        <form onSubmit={submitFormData} className="[&>div>input]:w-full [&>div>input]:py-3 [&>div>input]:px-2 [&>div>input]:rounded-md [&>div>input]:bg-[#EEF1F5]">
          {isLoaded ?
            <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="relative">
                <input {...getInputProps({ placeholder: "Search Address" })} value={address} />
                {error && (
                    <p className="text-sm capitalize mt-1" style={{ color: "red" }}>{error}</p>
                    )}
                <div className="shadow-md max-h-[170px] overflow-y-auto absolute z-50 w-full bg-white [&>div]:py-1 px-2">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    return (
                      <div key={suggestion?.id} {...getSuggestionItemProps(suggestion, { className })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete> : null  
        }
          <div id="map-canvas" className="mt-5">{map}</div>

          <div className="basis-full flex justify-between mt-6 flex-row-reverse">
              <Button type="submit" disabled={loading} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainColor text-white mt-4">
                  submit
              </Button>

              <Button className="bg-slate-400 text-white" onClick={prevStep}>
                  Prev.
              </Button>
          </div>
        </form>
     );
}
 
export default PartnerMap;