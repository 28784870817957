import { Box, Button, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";

const WarningModal = ({open,close,type,deleteFunc,toggleOfferActivaition}) => {
    const dispatch = useDispatch()
    const {t,i18n} = useTranslation()
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '1px solid #ddd',
        boxShadow: 24,
        px: 1,
        py:2,
        borderRadius : '10px'
    };


    return ( 
        <>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-warning"
                aria-describedby="modal-warning-delete"
            >
                <Box 
                    sx={style} 
                    dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'} 
                >
                    <div className="warnning-modal-wrapper">
                        <div className="flex justify-end items-center">
                            <div>
                                <Button color="error" className="text-2xl -mt-2" onClick={close}><IoCloseCircleOutline /></Button>
                            </div>
                        </div>

                        <div className="capitalize text-center w-full font-semibold text-mainRed text-lg mt-4">
                            <h2> {type === 'delete' ? t('offers.warning_modal.delete_body') : t('offers.warning_modal.deactivate_body')} </h2>
                        </div>

                        <div className={`flex gap-x-7 items-center mt-8 w-full justify-end  ${localStorage.getItem('i18nextLng') === 'ar' ? 'pl-4 flex-row-reverse' : 'pr-4'} `}>
                            <Button className="bg-gray-400 text-white" onClick={close}>{t('offers.warning_modal.cancel_btn')}</Button>
                            <Button className="bg-mainRed text-white" onClick={type === 'delete' ? ()=>{
                                deleteFunc()
                                dispatch(resetPage());
                            } : ()=>{
                                toggleOfferActivaition('deactivate')
                                dispatch(resetPage());
                            }}>{t('offers.warning_modal.yes_btn')}</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default WarningModal;