import logo from '../Assets/imgs/logo.svg'
import collapseLogo from '../Assets/imgs/collapse-logo.svg'
import linth from '../Assets/imgs/Linth.svg'
import { useEffect, useState } from 'react'
import StepOne from '../Components/StepForm-Components/stepOne'
import StepTwo from '../Components/StepForm-Components/stepTwo'
import Final from '../Components/StepForm-Components/final'
import { useDispatch, useSelector } from 'react-redux'
import {BsCheckLg} from 'react-icons/bs'

const PartnerStepForm = () => {

    const [isMobile,setisMobile] = useState(false)
    // used to check if the window width less that 768 to swap between image srcs for clear responsive view
    useEffect( ()=>{
        const handleResize = ()=>{
            setisMobile(window.innerWidth < 768)
        }
        window.addEventListener('resize',handleResize)
        handleResize()

        // clean up function 
        return ()=> window.removeEventListener('resize',handleResize)
    },[] )

    const data = useSelector(state=>state?.multiStepForm)
    const dispatch = useDispatch()

    // handling step form logic
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const {status} = useSelector(state=>state?.multiStepForm)
    const {success} = useSelector(state=>state?.multiStepForm)

    // console.log(success);

    const renderStepProgress = () => {
        return (
            <div className="step-progress">
                <div className={`step-container grow`}>
                    <p className={`step grow ${status?.stepOne ? "active" : ""}`}></p>
                </div>
                <div className={`step-container grow`}>
                    <p className={`step grow ${status?.stepTwo ? "active" : ""}`}></p>
                </div>
                <div>
                    <p className={`step grow ${status?.stepThree ? "active" : ""}`}></p>
                </div>
            </div>
        );
    };


    const formStepHandler = ()=> {

        switch (step) {
            case 1:
            return (
                <div className="step-one-wrapper">
                    <div>
                        <StepOne
                            nextStep={nextStep}
                        />
                    </div>
                </div>
            );
            case 2:
            return (
                <div className="step-two-wrapper">
                    <div>
                        <StepTwo
                            nextStep={nextStep}
                            prevStep={prevStep}
                        />
                    </div>
                </div>
            );
            
            case 3:
            return (
                <div className="final-step-wrapper">
                    <div>
                        <Final prevStep={prevStep} />
                    </div>
                </div>
            );
            
            default:
                return <div className="App"></div>;
        }
    }

    // console.log(data);
    return ( 
        <>
            <article className="partner-form-wrapper h-screen w-full">
            <div className="partner-form-sections-wrapper grid grid-cols-2 h-full">

                <section className="partner-form-banner-wrapper w-full h-full bg-mainColor relative">
                    <div className="banner-logo-img-wrapper text-center w-full pt-40">
                        <img src={isMobile ? collapseLogo : logo } alt="logo" className='m-auto w-[340px] h-[130px]' />
                    </div>

                    <div className='form-banner-linth absolute bottom-0 left-0'>
                        <img src={linth} alt='linth' className='m-auto lg:w-[410px] lg:h-[340px]' />
                    </div>
                </section>

                <section className="partner-form-wrapper w-full h-full bg-white p-5">
                    {!success ? 
                        <div>
                            <div className='step-proress-wrapper'>
                                {renderStepProgress()}
                            </div>

                            <div className='form-steps-wrapper mt-8 shadow-md p-3 [&>p]:text-lg [&>p]:text-mainColor [&>p]:font-semibold [&>p]:mb-5 [&>p]:font-playfair'>
                                {
                                    step === 1 ? <p>Personal Information</p> : step === 2 ? <p>Restaurant Information</p> : step === 3 ? <p>Address Information</p> : null
                                }
                                {formStepHandler()}
                            </div>
                        </div> :

                        <div className='h-full lg:m-auto flex items-center justify-center'>

                            <div className='flex flex-col justify-around items-center shadow-lg rounded-lg lg:h-[80%] p-10 w-full lg:w-[70%]'>
                                <div className='p-6 bg-mainYellow text-center rounded-[50%] text-white'>
                                    <BsCheckLg className='m-auto w-[100px] h-[100px]' />
                                </div>

                                <div className=' m-auto mt-10 capitalize text-lg font-semibold text-center'>
                                    <p>your request is sent successfully,<br/> someone will contact you shortly</p>
                                </div>
                            </div>

                        </div>

                    }
                </section>
            </div>        
        </article>
        </>
    );
}

export default PartnerStepForm;