import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { saveData, toggleStatus } from "../Redux/Slice/MultiStepForm/multiStepFormSlice";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { addFile } from "../Redux/Slice/Upload/file";
import IBAN from "iban";
import { useTranslation } from "react-i18next";

const StepTwo = ({ nextStep, prevStep}) => {
    const {t, i18n} = useTranslation()
    const [isImage, setisImage] = useState(null)

    const { handleSubmit, register, setValue, getValues,formState,setError } = useForm();
    const {errors} = formState

    const handleImageChange = (e)=> {
        const file = e.target.files[0];

        if(file){
            setisImage(file)
            const reader = new FileReader()
            reader.readAsDataURL(file)
            // reader.onload = ()=>{
            //     setPreview(reader.result);
            // }
        }
    }


    const {values} = useSelector(state=>state?.multiStepForm)
    const dispatch = useDispatch()

    useEffect(() => {
        setValue('companyName', values?.companyName); 
        setValue('restaurantName', values?.restaurantName); 
        setValue('hotline', values?.hotline); 
        setValue('restaurantPhoneNumber', values?.restaurantPhoneNumber); 
        setValue('restaurantEmail', values?.restaurantEmail); 
        setValue('logoUrl', values?.logoUrl); 
        setValue('commercialRegisterationNumber', values?.commercialRegisterationNumber); 
        setValue('bankAccountNumber', values?.bankAccountNumber); 
        setValue('bankName', values?.bankName); 
}, [setValue,values])


    const {file} = useSelector(state=>state)

    const submitFormData = async (data) => {
        let validIBAN = IBAN.isValid(data?.bankAccountNumber)
        if(!validIBAN){
            setError('bankAccountNumber',{
                type: "manual",
                message: "Invalid IBAN",
            })
        }
        let formData = null
        const image = new FormData()
        image.append('file',isImage)

        let imageResponse = null

        if(validIBAN && data){

            if(!values?.logoUrl){
                imageResponse = await dispatch(addFile(image));

                if (imageResponse.payload?.data) {
                    formData = {
                        ...data, // include all other form data
                        logoUrl : imageResponse?.payload?.data?.url,
                    }
                    if(data && Object.keys(errors).length === 0){
                        dispatch(saveData(formData))
                        dispatch(toggleStatus({stepTwo:true}))
                        nextStep()
                    }
                }
            } else {
                if (values?.logoUrl) {
                    formData = {
                        ...data, // include all other form data
                    }
                    if(data && Object.keys(errors).length === 0){
                        dispatch(saveData(formData))
                        dispatch(toggleStatus({stepTwo:true}))
                        nextStep()
                    }
                }
            }
        }

    };

    return (
        <div>
            <form onSubmit={handleSubmit(submitFormData)} noValidate className="form flex flex-wrap gap-y-3 md:gap-x-1 xl:gap-x-1 justify-between [&>div>label]:block [&>div>label]:capitalize [&>div>label]:text-sm [&>div>label]:text-[#515151] [&>div>label]:font-semibold [&>div>label]:mb-1 [&>div>input]:w-full [&>div>input]:py-3 [&>div>input]:px-2 [&>div>input]:rounded-md [&>div>input]:bg-[#EEF1F5]">
                <div className="basis-full lg:basis-[49%]">
                    <label htmlFor="companyName">Company Name</label>
                    <input type="text" id="companyName" {...register('companyName',{
                        required: {
                            value: true,
                            message: t('common.required'),
                        },
                        })}
                        defaultValue={values?.companyName}
                    />
                    {errors?.companyName && (
                    <p style={{ color: "red" }}>{errors.companyName.message}</p>
                    )}
                </div>

                <div className="basis-full lg:basis-[49%]">
                    <label htmlFor="restaurantName">Restaurant Name</label>
                    <input
                        type="text"
                        id="text"
                        {...register("restaurantName",{
                            required: {
                            value: true,
                            message: t('common.required'),
                            },
                        })}
                        defaultValue={values?.restaurantName}
                    />
                    {errors?.restaurantName && (
                    <p style={{ color: "red" }}>{errors.restaurantName.message}</p>
                    )}
                </div>

                <div className="basis-full lg:basis-[49%]">
                    <label>Hotline</label>
                    <input type="text" id="hotline"
                        {...register('hotline',{
                            required:{
                                value : true,
                                message : t('common.required')
                            },
                            minLength : {
                                value : 5,
                                message : 'min length is 5 digits'
                            },
                            maxLength : {
                                value : 11,
                                message : 'max length is 11 digits'
                            },
                            pattern:{
                                value: /^(\+)?\d+$/,
                                message: 'Only numbers and (+) sign the beginning allowed'
                            }
                        })}
                        minLength={5}
                        maxLength={11}
                        defaultValue={values?.hotline}
                    />

                    {errors?.hotline && (
                    <p style={{ color: "red" }}>{errors.hotline.message}</p>
                    )}
                </div>

                <div className="basis-full lg:basis-[49%]">
                    <label>Restaurant Phone Number</label>
                    <input type="text" id="restaurantPhoneNumber"
                        {...register('restaurantPhoneNumber',{
                            required:{
                                value : true,
                                message : t('common.required')
                            },
                            minLength : {
                                value : 11,
                                message : 'min length is 11 digits'
                            },
                            maxLength : {
                                value : 22,
                                message : 'max length is 22 digits'
                            },
                            pattern:{
                                value: /^(\+)?\d+$/,
                                message: 'Only numbers and (+) sign the beginning allowed'
                            }
                        })}
                        minLength={11}
                        maxLength={22}
                        defaultValue={values?.restaurantPhoneNumber}
                    />

                    {errors?.restaurantPhoneNumber && (
                    <p style={{ color: "red" }}>{errors.restaurantPhoneNumber.message}</p>
                    )}
                </div>

                <div className="basis-full lg:basis-[49%]">
                    <label>Restaurant Email</label>
                    <input type="email" id="restaurantEmail"
                        {...register('restaurantEmail',{
                            pattern:{
                                value : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                message : 'Wrong Email Format'
                            },
                            required:{
                                value : true,
                                message : t('common.required')
                            },
                            minLength : {
                                value : 3,
                                message : 'min length is 3 characters'
                            },
                            maxLength : {
                                value : 50,
                                message : 'max length is 50 characters'
                            },
                        })}
                        minLength={3}
                        maxLength={50}
                        defaultValue={values?.restaurantEmail}
                    />

                    {errors?.restaurantEmail && (
                    <p style={{ color: "red" }}>{errors.restaurantEmail.message}</p>
                    )}
                </div>

                <div className="basis-full lg:basis-[49%]">
                    <label>Attach your Restaurant Logo</label>
                    <input type="file" id="logoUrl"
                        {...register('logoUrl',{
                            required:{
                                value : values?.logoUrl ? false : true,
                                message : t('common.required')
                            },
                        })}
                        onChange={handleImageChange}
                    />

                    {errors?.logoUrl && (
                    <p style={{ color: "red" }}>{errors.logoUrl.message}</p>
                    )}
                </div>

                <div className="basis-full lg:basis-[49%]">
                    <label htmlFor="commercialRegisterationNumber">Commercial Registration No.</label>
                    <input type="text" id="commercialRegisterationNumber" {...register('commercialRegisterationNumber',{
                            required: {
                            value: true,
                            message: t('common.required'),
                            },
                        })}
                        defaultValue={values?.commercialRegisterationNumber}
                    />
                    {errors?.commercialRegisterationNumber && (
                        <p style={{ color: "red" }}>{errors.commercialRegisterationNumber.message}</p>
                    )}
                </div>

                <div className="basis-full lg:basis-[49%]">
                    <label htmlFor="bankAccountNumber">IBAN Number</label>
                    <input type="text" id="bankAccountNumber" {...register('bankAccountNumber',{
                            required: {
                            value: true,
                            message: t('common.required'),
                            },
                        })}
                        defaultValue={values?.bankAccountNumber}
                    />
                    {errors?.bankAccountNumber && (
                    <p style={{ color: "red" }}>{errors.bankAccountNumber.message}</p>
                    )}
                </div>

                <div className="basis-full lg:basis-[49%]">
                    <label htmlFor="bankName">Bank Name</label>
                    <input type="text" id="bankName" {...register('bankName',{
                            required: {
                            value: true,
                            message: t('common.required'),
                            },
                        })}
                        defaultValue={values?.bankName}
                    />
                    {errors?.bankName && (
                        <p style={{ color: "red" }}>{errors.bankName.message}</p>
                    )}
                </div>

                <div className="basis-full flex justify-between mt-6 flex-row-reverse">
                    <Button type="submit" disabled={file?.loading} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainGreen text-white">
                        {file?.loading ? 'Loading...' : 'Continue'}
                    </Button>

                    <Button className="bg-slate-400 text-white" onClick={()=>prevStep()}>
                        Prev.
                    </Button>
                </div>

            </form>
        </div>
    );
};

export default StepTwo;
