import { FaRegFaceSadTear } from "react-icons/fa6";
export const NoCategory = () => {
    return ( 
        <>
            <div className="w-full h-full flex flex-col flex-wrap gap-y-4 justify-center items-center">
                <FaRegFaceSadTear className="w-16 h-16 text-mainColor" />
                <p className="capitalize text-mainColor font-semibold">{localStorage.getItem('i18nextLng') === 'ar' ? 'لا يوجد فئات متاحه' : 'no category found'}</p>
            </div>
        </>
    );
}

const NoDataEmoji = () => {
    return ( 
        <>
            <div className="w-full h-full flex flex-col flex-wrap gap-y-4 justify-center items-center">
                <FaRegFaceSadTear className="w-16 h-16 text-mainColor" />
                <p className="capitalize text-mainColor font-semibold">{localStorage.getItem('i18nextLng') === 'ar' ? 'لا يوجد بيانات متاحه' : 'no data found'}</p>
            </div>
        </>
    );
}

export default NoDataEmoji;