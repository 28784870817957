import { Navigation } from 'swiper';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { AiFillStar } from 'react-icons/ai';
import { useEffect, useRef } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getRating } from '../../Redux/Slice/Rating/rating';
import { RatingSkeletons } from '../../Skeleton/skeleton';
import { dateFormate } from '../../Common/dateFormate';


const DashReviewSlider = () => {
    const dispatch = useDispatch()
    const {rate} = useSelector(state=>state)

    let page = 1,
        size = 5
    useEffect(() => {
        dispatch(getRating({page,size}))
        // dispatch(getMyRestaurant())
    }, [])

    // const avgRating = rate?.rate?.data?.orderRating;
    const swiper = useSwiper();
    const swiperRef = useRef();

    // console.log(rate);
    return ( 
        <div dir='ltr'>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                modules={[Navigation]}
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
            >
                {
                    rate?.loading ? [...Array(1)].map((index)=><RatingSkeletons key={index} />) 
                    : 
                    rate?.rate?.data?.map( item=>(
                        // <Reviews data={item} key={item?.branchName} />
                        <SwiperSlide key={item?.id}>
                            <div className="review-customer-item">
                                <div className="flex justify-between items-center">
                                    <div className="capitalize">
                                        <p className="text-[#333] font-medium">{item?.customerName}</p>
                                        <div className="flex [&>span]:text-sm">
                                            {[...Array(5)].map((_,index)=>(
                                                index < item?.orderRating ? <span className='text-mainYellow'><AiFillStar /></span> : <span className='text-[#DADADA]'><AiFillStar /></span>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="text-[12px] text-[#999999]">
                                        <span>{dateFormate(item?.createdAt)}</span>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <p className="text-sm text-[#707070]">“{item?.orderFeedback}”</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ) )
                }          
            
        </Swiper>

        <div className='mt-2 text-right text-[#707070] text-xl [&>button]:ml-1'>
            <button onClick={() => swiperRef.current?.slidePrev()}><FaAngleLeft /></button>
            <button onClick={() => swiperRef.current?.slideNext()}><FaAngleRight /></button>
        </div>
        </div>
    );
}

export default DashReviewSlider;