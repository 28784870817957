import ReactApexChart from "react-apexcharts";
import salesIcon from '../../../../Assets/imgs/greenSales.svg'
import takeInIcon from '../../../../Assets/imgs/yellowSales.svg'

const ReportLeft = ({series,options,activeTab,totalSales,takeinShare,restaurantShare,t}) => {
    // console.log(series);
    return ( 
        <>
            <div className='px-4 pr-0 h-full'>
                <section className='chart-title flex justify-between items-start'>
                    <div>
                        <h2 className='font-bold text-xl text-[#464E5F]'>{t('dashboard.revenue.title')}</h2>
                    </div>
                </section>

                <section className="sales-wrapper text-[11px] text-[#B5B5C3] font-[500]">
                    <span>{totalSales} {t('dashboard.revenue.sales')}</span>
                </section>

                <section className="revenue-data-numbers mt-3 mb-2 flex gap-x-24">
                    <div className='text-center w-fit'>
                        <label className='text-[#999999] text-sm capitalize'>{t('reports.restaurant_sales')}</label>
                        <p className='flex'><img src={salesIcon} alt='chart-icon' className={`${localStorage?.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`} /> <span className='text-[#464E5F] font-bold'>{t('common.sar')} {restaurantShare?.toLocaleString()}K</span></p>
                    </div>

                    <div className='text-center w-fit'>
                        <label className='text-[#999999] text-sm capitalize'>{t('dashboard.revenue.chart.takeinShare')}</label>
                        <p className='flex'><img src={takeInIcon} alt='chart-icon' className={`${localStorage?.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`} /> <span className='text-[#464E5F] font-bold'>{t('common.sar')} {takeinShare?.toLocaleString()}K</span></p>
                    </div>
                </section>

            </div>

            <section className="chart-wrapper grow mt-4 h-full" dir="ltr">
                <ReactApexChart
                    options={options}
                    series={series || []}
                    type="bar"
                    width="100%"
                    height="500px"
                />
            </section>
        </>
     );
}
 
export default ReportLeft;