import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getOrders = createAsyncThunk(
  "getOrdersFunc",
  async (
    { page, size, col, dir, searchValue, orderType, selectedDate },
    { rejectWithValue, dispatch }
  ) => {
    // this code is commented for later using
    // let from_date = null,
    //   adjustedFromDate = null,
    //   to_date = null,
    //   adjustedToDate = null;

    // if (selectedDate) {
    //   from_date = new Date(selectedDate?.startDate)
    //   adjustedFromDate = new Date(from_date.getTime() + (from_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);
    //   to_date = new Date(selectedDate?.endDate)
    //   adjustedToDate = new Date(to_date.getTime() + (to_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);
    // }

    // console.log(selectedDate);

    // let url = selectedDate ? `/ratings?page=${page}&size=${size}&from_date=${adjustedFromDate}&to_date=${adjustedToDate}` : `/ratings?page=${page}&size=${size}`
    // console.log(orderType);
    let colCheck;

    if (col) {
      colCheck =
        col === "Created at" || col === "تم انشاءه في"
          ? "created_at"
          : col === "Delieverd at" || col === "تم التوصيل في"
          ? "delivered_at"
          : col === "Updated at" || col === "تم التعديل في"
          ? "updated_at"
          : col === "Restaurant Name" || col === "اسن المطعم"
          ? "restaurant_name"
          : col === "Courier Name" || col === "اسم السائق"
          ? "courier_name"
          : col === "Courier Rating" || col === "تقييم السائق"
          ? "courier_rating"
          : col === "Courier Number" || col === "رقم السائق"
          ? "courier_mobile_number"
          : col === "Customer Name" || col === "اسم العميل"
          ? "customer_name"
          : col === "Customer Number" || col === "رقم العميل"
          ? "customer_mobile_number"
          : col === "Customer Address" || col === "عنوان العميل"
          ? "customer_address"
          : col === "Order Rating" || col === "تقييم الطلب"
          ? "order_rating"
          : col === "Branch Name" || col === "اسم الفرع"
          ? "branch_name"
          : col === "Branch Number" || col === "رقم الفرع"
          ? "branch_number"
          : col === "Branch Address" || col === "عنوان الفرع"
          ? "branch_address"
          : col === "Min Charge" || col === "الحد الادنى"
          ? "minimum_charge"
          : col === "Avg Delivery Time" || col === "متوسط وقت التوصيل"
          ? "average_delivery_time"
          : col === "Avg Rating" || col === "متوسط التقييم"
          ? "average_rating"
          : col === "Total Orders" || col === "اجمالى الطلبات"
          ? "total_orders"
          : col === "Delivery Charges" || col === "رسم التوصيل"
          ? "delivery_charges"
          : col === "Pickup Schedule" || col === "وقت الاستلام"
          ? "pickup_schedule"
          : col === "Vehicle Plate" || col === "لوحه المركبة"
          ? "vehicle_plate"
          : col === "Original Price" || col === "السعر الاساسى"
          ? "original_price"
          : col === "Final Price" || col === "السعر النهائي"
          ? "final_price"
          : col.replace(/\s+/g, "");
    }

    const url = !col
      ? `/orders?page=${page ? page : 1}&size=${size ? size : 10}${
          searchValue ? `&search=${searchValue}` : ""
        }${orderType ? `&orderType=${orderType}` : ""}${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }`
      : `/orders?page=${page ? page : 1}&size=${size ? size : 10}${
          searchValue ? `&search=${searchValue}` : ""
        }${
          orderType ? `&orderType=${orderType}` : ""
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${
          selectedDate
            ? `&from_date=${selectedDate?.fromDate}&to_date=${selectedDate?.toDate}}`
            : ""
        }`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOrdersById = createAsyncThunk(
  "getOrdersByIdFunc",
  async (id, { rejectWithValue, dispatch }) => {
    const url = `/orders/${id}`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOrdersTypes = createAsyncThunk(
  "getOrdersTypesFunc",
  async (_, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await axios.get(`/order-types`);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getInvoice = createAsyncThunk(
  "getInvoiceFunc",
  async (id, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await axios.get(`/orders/${id}/invoice`);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssignHistory = createAsyncThunk(
  "getAssignHistoryFunc",
  async (id, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await axios.get(`/orders/${id}/assignee-history`);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  orders: [],
  ordersType: [],
  singleOrder: [],
  assignHistory: [],
  invoice: null,
  error: null,
};
export const OrdersSlice = createSlice({
  name: "OrdersSlice-Func",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.orders = action.payload;
        // console.log(action.payload);
      })

      .addCase(getOrders.rejected, (state, action) => {
        state.loading = false;
        state.orders = [];
        state.error = action.payload;
      })

      .addCase(getOrdersTypes.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getOrdersTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.ordersType = action.payload;
        // console.log(action.payload);
      })

      .addCase(getOrdersTypes.rejected, (state, action) => {
        state.loading = false;
        state.ordersType = [];
        state.error = action.payload;
      })
      .addCase(getOrdersById.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getOrdersById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.singleOrder = action.payload;
        // console.log(action.payload);
      })

      .addCase(getOrdersById.rejected, (state, action) => {
        state.loading = false;
        state.singleOrder = [];
        state.error = action.payload;
      })

      .addCase(getInvoice.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.invoice = action.payload;
        // console.log(action.payload);
      })

      .addCase(getInvoice.rejected, (state, action) => {
        state.loading = false;
        state.invoice = null;
        state.error = action.payload;
      })

      .addCase(getAssignHistory.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getAssignHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.assignHistory = action.payload;
        // console.log(action.payload);
      })

      .addCase(getAssignHistory.rejected, (state, action) => {
        state.loading = false;
        state.assignHistory = null;
        state.error = action.payload;
      });
  },
});

export default OrdersSlice.reducer;
