import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './Components/Redux/store';
import { BrowserRouter as Router} from 'react-router-dom';
import Overlay from './Components/Common/overlay';
import '../src/locales/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Suspense fallback={<Overlay />}>
        <Provider store={store}>
          <Router>
            {/* <Suspense fallback={
                <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full flex justify-center items-center bg-[rgba(0,0,0,.5)]'>
                  <img src={lazyloadingimg} loading='eager' alt='Loading...' className='w-[200px] h-[200px]' />
                </div>
              }> */}
              <App />
            {/* </Suspense> */}
          </Router>
      </Provider>
    </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
