import { Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { resetPassword } from "../Redux/Slice/Auth/auth"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { GrPrevious } from "react-icons/gr"
import AltLayout from "../Alt-layout/altLayout"
import { useTranslation } from "react-i18next"

const NewPassword = () => {
    const {t, i18n} = useTranslation()
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue} = form
    const {errors} = formState
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('token');
    
    // console.log(paramValue);
    useEffect(() => {
        setValue('token',paramValue)
    }, [])
    
    const submitHandler = async (data)=>{
        // console.log(data?.password === data?.confirmPassword);

        if(data && (data?.newPassword === data?.confirmPassword)){
            dispatch(resetPassword(data)).then( (e)=>{
                // console.log(e?.payload?.mesage);
                if(e?.payload?.mesage === 'SUCCESS'){
                    navigate('/login')
                }
            } )
        } else {
            toast.error('Password and Confirm Password does not match')
        }
        // dispatch(resetPassword(data)).then( (e)=>{
        //     if(e.message === 'SUCCESS'){
        //         navigate('/login')
        //     }
        // } )
        // if(data){
        //     navigate('/login')
        // }
    }

    return ( 
        <div className="h-full flex flex-col justify-between">
            <AltLayout>
                <article className="forget-password-wrapper">
                    <div className="w-full flex justify-center items-center">
                        <div className="min-w-[350px] w-full md:w-[60%] lg:w-1/2 bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center gap-x-3 ">
                                    <div className='previous-page-icon text-2xl cursor-pointer' onClick={()=>navigate('/login')}>
                                        <GrPrevious />
                                    </div>
                                    <div className="w-full text-left verfication-email-title-wrapper capitalize font-medium text-[#333] text-xl font-playfair">Reset Password</div>
                                </div>
                            <form onSubmit={handleSubmit(submitHandler)} className="mt-5 w-[85%] m-auto">
                                
                                <div className="[&>div]:mb-2">
                                    {/* <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                        <label>Old password</label>
                                        <input type="password" id="oldPassword"
                                            {...register('oldPassword',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                }
                                            })} 
                                            // defaultValue={profile?.profile?.data?.oldPassword}
                                        />

                                        {errors?.oldPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.oldPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter old password</p>}
                                    </div> */}

                                    <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                        <label>New password</label>
                                        <input type="password" id="newPassword"
                                            {...register('newPassword',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                }
                                            })} 
                                            // defaultValue={profile?.profile?.data?.newPassword}
                                        />

                                        {errors?.newPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.newPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter new password</p>}
                                    </div>

                                    <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                        <label>confirm password</label>
                                        <input type="password" id="confirmPassword"
                                            {...register('confirmPassword',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                }
                                            })} 
                                            // defaultValue={profile?.profile?.data?.confirmPassword}
                                        />

                                        {errors?.confirmPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.confirmPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter confirm password</p>}
                                    </div>
                                </div>

                                <div className="flex justify-center mt-7">
                                    <Button type="submit" className="text-white py-2 px-8 rounded-full bg-mainColor">Send</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </article>
            </AltLayout>
        </div>
    );
}

export default NewPassword;