import { Box, Button, IconButton, Modal } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { getSubPackages, unSubscribePackage } from "../../../Components/Redux/Slice/Packages/packages";


const style = {
    position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 3,
    p: 2,
    borderRadius : 5
};

const UnsubscribeModal = ({open,close,t,id}) => {
    const dispatch = useDispatch()
    const page = 1,
        size = 12


    const handleOfferDpSubmit = (e)=>{
        e.preventDefault()
        const data = {
            packageId : id
        }
        dispatch(unSubscribePackage(data)).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                dispatch(getSubPackages({page,size}))
                close()
            }
        } )
    }
    
    const handleClose = ()=>{
        close()
    }
    return ( 
        <>
            <Modal
                open={open}
                onClose={handleClose}
                // aria-labelledby="menu-item-delete-modal-title"
                // aria-describedby="menu-item-delete-modal-description"
            >
                <Box 
                    sx={style}
                    dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
                >
                    <form onSubmit={handleOfferDpSubmit} noValidate className='p-1'>
                        <div className="flex justify-between items-center mb-4 border-b">
                            <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">{t('dp.sub.to.offers')}</h2>

                            <div>
                                <IconButton color="error" className="text-2xl -mt-2" onClick={handleClose}><IoCloseCircleOutline /></IconButton>
                            </div>
                        </div>

                        <section className="mb-4">
                            <p className="font-semibold capitalize text-mainRed text-center mt-2">{t('dp.warn')}</p>
                        </section>

                        <div className="flex items-center justify-end gap-x-3 [&>button]:capitalize [&>button]:min-w-[150px]">
                            <Button onClick={handleClose} className="bg-transparent text-mainRed border border-solid border-mainRed">{t('common.cancel')}</Button>
                            <Button type="submit" className="bg-mainRed text-white">{t('dp.unsubscripe')}</Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
     );
}
 
export default UnsubscribeModal;