import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const NewPasswordRole = () => {
    const location = useLocation()
    const auth = useSelector(state=>state.auth)
   
    return ( 
        
        ((auth?.passwordResetVerification === true && localStorage.getItem('tk')) || ( (localStorage.getItem('prv') && localStorage.getItem('prv') === 'true') && localStorage.getItem('tk') ) ) 
        ? <Outlet /> 
        : ((auth?.passwordResetVerification === false && localStorage.getItem('tk')) || ( (localStorage.getItem('prv') && localStorage.getItem('prv') === 'false') && localStorage.getItem('tk') ) ) 
        ? <Navigate to='/firstnewpassword' state={{from:location}} replace />
        : null
    );
}

export default NewPasswordRole;