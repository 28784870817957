import logo from '../Assets/imgs/logo.svg'
import collapseLogo from '../Assets/imgs/collapse-logo.svg'
import linth from '../Assets/imgs/Linth.svg'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../Components/Redux/Slice/Auth/auth'
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
const Login = () => {
    const {t, i18n}  = useTranslation()
    const state = useSelector(state=>state)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isMobile,setisMobile] = useState(false)
    const {auth} = useSelector(state=>state)

    // used to check if the window width less that 768 to swap between image srcs for clear responsive view
    useEffect( ()=>{
        const handleResize = ()=>{
            setisMobile(window.innerWidth < 768)
        }
        window.addEventListener('resize',handleResize)
        handleResize()

        // clean up function 
        return ()=> window.removeEventListener('resize',handleResize)
    },[] )

    /* UseForm Hook */
    const form = useForm()
    const {register,control,handleSubmit,formState} = form
    const {errors,isDirty} = formState

    // console.log(errors);

    const submitHandler = async (data)=>{
        // console.log(data);
        dispatch(login(data)).then( (e)=>{
            // console.log(e?.payload?.user?.passwordResetVerification);
            if(e?.payload?.message === 'SUCCESS'){
                if(e?.payload?.user?.passwordResetVerification === false){
                    navigate('/firstnewpassword',{ replace: true })
                } else if(e?.payload?.user?.passwordResetVerification === true){
                    navigate('/dashboard/home',{ replace: true })
                }
            }
        } )
        i18n.changeLanguage('en');
        // if(!localStorage.getItem('i18nextLng')){
        //     localStorage.setItem('i18nextLng','en')
        // }
    }
    
    useEffect(() => {
        if(localStorage.getItem('tk') && localStorage.getItem('tk') !== 'undefined' && (state?.auth?.passwordResetVerification === true || localStorage.getItem('prv') === 'true') ){
            navigate('/dashboard/home')
        }
    }, [state?.auth?.token,localStorage.getItem('tk'),localStorage.getItem('prv')])

    // useEffect(() => {
    //     // if(auth?.passwordResetVerification === true){
    //     //     if(localStorage.getItem('tk') && localStorage.getItem('tk') !== 'undefined' && auth?.passwordResetVerification === true){
    //     //         navigate('/dashboard/home')
    //     //     }
    //     // }

    //     if(localStorage.getItem('tk') && localStorage.getItem('tk') !== 'undefined'){
    //         navigate('/dashboard/home')
    //     }
    // }, [state?.auth?.token,localStorage.getItem('tk')])
    
    // console.log(state?.auth);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    // for redirect link changer
    let url = 'https://takein.sa/signup.html'
    if(window.location.hostname === 'partner.takein.sa'){
        url = 'https://takein.sa/signup.html'
      } else if(window.location.hostname === 'partner-staging.takein.sa'){
        url = 'https://web-staging.takein.sa/signup'
      } else if (window.location.hostname === 'partner-develop.takein.sa'){
        url = 'https://web-develop.takein.sa/signup'
      } else if(window.location.hostname === 'localhost'){
        url = 'https://web-develop.takein.sa/signup'
      }

      
    return ( 
        <article className="login-wrapper h-screen w-full">
            <div className="login-sections-wrapper grid grid-cols-2 h-full">
                {/* This Section for the left side of the page with logo and login type*/}
                <section className="login-banner-wrapper w-full h-full bg-mainColor relative">
                    {/* <div className="banner-title py-10 px-6 rounded-md overflow-hidden">
                        <span className="capitalize p-2 bg-mainGreen text-white font-semibold text-sm rounded-md">admin login</span>
                    </div> */}

                    <div className="banner-logo-img-wrapper text-center w-full pt-40">
                        <img src={isMobile ? collapseLogo : logo } alt="logo" className='m-auto w-[340px] h-[130px]' />
                    </div>

                    <div className='login-banner-linth absolute bottom-0 left-0'>
                        <img src={linth} alt='linth' className='m-auto lg:w-[410px] lg:h-[340px]' />
                    </div>
                </section>

                {/* This Section for the right side of the page with the login form */}
                <section className="login-form-wrapper w-full h-full bg-white">
                    <div className='form-wrapper m-auto pt-32 pl-11 md:pl-20 lg:pl-32 xl:pl-44'>
                        <div className='login-form-title [&>p]:capitalize [&>p]:text-[#2F3044] [&>p]:font-bold text-2xl text-left [&>p]:font-playfair'>
                            <p>welcome to take in</p>
                            <p>Partner portal</p>
                            <div className='capitalize text-[14px] mt-2'>
                                <span className='text-[#A7A8BB] mr-1 font-playfair'>new here?</span>
                                <span className='text-mainColor font-bold font-playfair'><Link to={url} target='_blank' className='font-playfair'>join now!</Link></span>
                            </div>
                        </div>

                        <form noValidate onSubmit={handleSubmit(submitHandler)} className='form w-[95%] md:w-[75%] lg:w-[70%] xl:w-[55%] mt-10 [&>div]:mb-5 [&>div>label]:block [&>div>label]:capitalize [&>div>label]:text-[#2f3044] [&>div>label]:font-bold [&>div>label]:font-playfair [&>div>label]:mb-1 [&>div>input]:w-full [&>div>input]:py-4 [&>div>input]:px-2 [&>div>input]:rounded-md [&>div>input]:bg-[#EEF1F5]'>
                            <div>
                                <label htmlFor='username'>Username</label>
                                <input type='text' id='username' {...register('username',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                    })} />
                                    {errors?.username?.message && <p className="capitalize text-sm text-danger">{errors?.username?.message}</p>}
                            </div>

                            <div>
                                <div className='[&>label]:text-[#2f3044] [&>label]:font-bold [&>label]:mb-1 capitalize flex justify-between items-center'>
                                    <label htmlFor='password' className='font-playfair'>password</label>
                                    <Link to='/forgetpassword' className='text-[12px] text-mainColor font-bold font-playfair'>forgot password!</Link>
                                </div>
                                {/* <input type='password' id='password' {...register('password')} /> */}

                                <FormControl className='bg-[#EEF1F5] rounded-md' sx={{ width: '100%',border:0,'& .MuiFormControl-root':{border:0,backgroundColor:'#EEF1F5'},'fieldset':{border:0}, '& .css-1bn53lx' : {backgroundColor:'#EEF1F5'} }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('password',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                        })}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            // aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                {errors?.password?.message && <p className="capitalize text-sm text-danger">{errors?.password?.message}</p>}
                            </div>

                            <div className='submit-btn mt-8'>
                                <button type='submit' disabled={state?.auth?.loading} className='disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor px-8 py-2 rounded-md text-white font-semibold'>sign in</button>
                            </div>
                        </form>
                    </div>

                    <footer className='login-footer mt-16 w-[70%] mx-auto'>
                        <ul className='capitalize flex items-center justify-evenly text-center [&>li]:mr-6 md:[&>li]:mr-12 [&>li>a]:text-mainColor [&>li>a]:font-playfair font-bold'>
                            <li>
                                <Link to='/termsconditions'>terms & conditions</Link>
                            </li>

                            <li>
                                <Link to='/privacypolicies'>Privacy & Policies</Link>
                            </li>

                            <li>
                                <Link to='/aboutus'>About us</Link>
                            </li>
                        </ul>
                    </footer>
                </section>
            </div>        
        </article>
    );
}

export default Login;